import { createActionGroup, emptyProps } from '@ngrx/store';
import { CartUpdateRequest } from '../../services/cart/models/cart-record';
import { MaterialRecommendationsRecord } from '../../../shared/services/material-recommendations/models/material-recommendations-record';
import {
  FacebookEvent,
  FacebookEventType,
} from '../../../shared/analytics/facebook-tracking/facebook-events';
import { FacebookConversionsResponse } from '../../../shared/analytics/facebook-conversions/models/facebook-conversions-response';
import { EcommerceEvent } from '../../services/ecommerce-analytics/models/ecommerce-event';
import {
  AuxiliaryAnalyticsData,
  GAEvent,
  GAEventName,
  PromotionData,
  PromotionEventData,
} from '../../services/ecommerce-analytics/models/google-events';
import { MaterialQuantityDetail } from '../shared/shared';
import { MaterialSponsoredSearchState } from './ecommerce-analytics.state';
import { MaterialRowContext } from '../material-row/models/material-row';

export const EcommerceAnalyticsActions = createActionGroup({
  source: 'Ecommerce Analytics',
  events: {
    'Update Material Recommendations': (
      materialRecommendations: MaterialRecommendationsRecord[],
    ) => ({ materialRecommendations }),
    'Clear Material Recommendations': emptyProps(),
    'Ecommerce Event': (ecommerceEvent: EcommerceEvent) => ({ ecommerceEvent }),
    'Google Analytics Event': (googleAnalyticsEvent: GAEvent) => ({
      googleAnalyticsEvent,
    }),
    'Recommendation View Event': (materialNumber: string) => ({
      materialNumber,
    }),
    'Recommendation Click Event': (materialNumber: string) => ({
      materialNumber,
    }),
    'Promotion View Event': (data: PromotionEventData) => ({ data }),
    'Promotion Click Event': (data: PromotionEventData) => ({ data }),
    'Material Details View Event': (materialNumber: string) => ({
      materialNumber,
    }),
    'Update Cart Event': (cartUpdateRequest: CartUpdateRequest) => ({
      cartUpdateRequest,
    }),
    'Page View Event': (url: string) => ({ url }),
    'Checkout Event': emptyProps(),
    'Begin Checkout Event': emptyProps(),
    'Purchase Event': (hasPendingOrders: boolean) => ({
      hasPendingOrders,
    }),
    'Clear Ecommerce Data': emptyProps(),
    'Send Facebook Conversions': (
      eventType: FacebookEventType,
      event?: FacebookEvent,
    ) => ({ eventType, event }),
    'Send Facebook Conversions Success': (
      payload: FacebookConversionsResponse,
    ) => ({ payload }),
    'Send Facebook Conversions Failure': (payload: unknown) => ({ payload }),
    'Search Filter Event': (category: string, value: string) => ({
      category,
      value,
    }),
    'Select Ship Date Event': (selectedDate: string) => ({ selectedDate }),
    'Google Promotion Event': (
      eventName: GAEventName,
      promotionData: PromotionData,
    ) => ({
      eventName,
      promotionData,
    }),
    'Sponsored Search Results Event': emptyProps(),
    'Google Saved Cart Event': (activateSavedCart: boolean) => ({
      activateSavedCart,
    }),
    'Google Customer Login Event': (isLoggingIn: boolean) => ({
      isLoggingIn,
    }),
    'Google Add Cart Event': (
      materialQuantities: MaterialQuantityDetail[],
      context: MaterialRowContext,
      analytics?: AuxiliaryAnalyticsData,
    ) => ({
      materialQuantities,
      context,
      analytics,
    }),
    'Google Remove Cart Event': (
      materialQuantities: MaterialQuantityDetail[],
      analytics?: AuxiliaryAnalyticsData,
    ) => ({ materialQuantities, analytics }),
    'Google View Item List Event': (
      materialNumbers: string[],
      context: MaterialRowContext,
      data?: {
        analytics?: AuxiliaryAnalyticsData;
        startIndex?: number;
      },
    ) => ({
      materialNumbers,
      context,
      data,
    }),
    'Update Sponsored Data': (
      materialSponsoredSearchStates: MaterialSponsoredSearchState[],
    ) => ({
      materialSponsoredSearchStates,
    }),
    'Redeem Check Request': (numberOfChecks: number) => ({
      numberOfChecks,
    }),
    'Google View Item Event': (
      materialNumber: string,
      materialRowContext: MaterialRowContext,
      analytics?: AuxiliaryAnalyticsData,
    ) => ({
      materialNumber,
      materialRowContext,
      analytics,
    }),
    'Google Select Item Event': (
      materialNumber: string,
      materialRowContext: MaterialRowContext,
      analytics?: AuxiliaryAnalyticsData,
    ) => ({
      materialNumber,
      materialRowContext,
      analytics,
    }),
    'Add To Cart Conflict': (
      modalName: string,
      addToCartType: string,
      index: number,
      actionId: number,
      ctaText: string,
    ) => ({
      modalName,
      addToCartType,
      index,
      actionId,
      ctaText,
    }),
    'Update Behavioral Advertising': (behavioralAdvertising: boolean) => ({
      behavioralAdvertising,
    }),
  },
});
