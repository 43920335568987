import {
  StoreHours,
  StoreRecord,
} from '../../services/store/model/store-record';
import moment, { Moment } from 'moment';
import { Locale } from '../../services/session/models/session-record';
import { NaooConstants } from '../../../shared/NaooConstants';

const SUNDAY = 0;
const MONDAY = 1;
const TUESDAY = 2;
const WEDNESDAY = 3;
const THURSDAY = 4;
const FRIDAY = 5;
const SATURDAY = 6;

export function getPickupTime(record: StoreRecord, date: Moment): string {
  let selectedDateTime = date;
  const selectedDate = date.format('YYYY-MM-DD');
  const openingTime = getOpeningTime(record, selectedDate, selectedDateTime);
  if (openingTime) {
    selectedDateTime = moment.tz(
      `${selectedDate} ${openingTime}`,
      record.timezone,
    );
    const now = moment();
    if (selectedDateTime < now) {
      selectedDateTime = now.tz(record.timezone);
    }
    return selectedDateTime.format();
  }
  return selectedDate;
}

export function getStoreHoursDisplayForDayOfWeek(
  dayOfWeek: number,
  storeHours: StoreHours,
  locale: Locale,
  minutesOffset: number,
): string {
  const openTime = getAdjustedTimeUsingOffset(
    getOpenTime(dayOfWeek, storeHours),
    minutesOffset,
  );
  const closeTime = getAdjustedTimeUsingOffset(
    getCloseTime(dayOfWeek, storeHours),
    minutesOffset,
  );
  const dayOfWeekLetter =
    Locale.fr_CA === locale
      ? NaooConstants.frDayOfWeekNames[dayOfWeek]
      : NaooConstants.enDayOfWeekNames[dayOfWeek];

  return `${dayOfWeekLetter} ${openTime}-${closeTime}`;
}

export function getAdjustedTimeUsingOffset(
  hours: string,
  minutesOffset: number,
): string {
  return moment
    .utc()
    .startOf('day')
    .add(hours, 'hours')
    .add(minutesOffset, 'minutes')
    .format('h:mmA');
}

export function getOffsetDifferenceInMinutes(
  customerTimeZone: string,
  storeTimeZone: string,
): number {
  const userOffset = moment.tz(customerTimeZone).utcOffset();
  const storeOffset = moment.tz(storeTimeZone).utcOffset();
  return userOffset - storeOffset;
}

function getOpeningTime(
  record: StoreRecord,
  selectedDate: string,
  selectedDateTime: moment.Moment,
): string | undefined {
  const holidayRecord = record.hours.holidays.find(
    (holiday) => moment(holiday.date).format('YYYY-MM-DD') === selectedDate,
  );
  const openTime = holidayRecord
    ? (holidayRecord.openTime ?? undefined)
    : getOpenTime(selectedDateTime.day(), record.hours);

  if (openTime) {
    return moment
      .tz(`${selectedDate} ${openTime}`, record.timezone)
      .add(NaooConstants.pickupOpeningBufferMinutes, 'minutes')
      .format('HH:ss');
  }
  return undefined;
}

function getOpenTime(dayOfWeek: number, hours: StoreHours): string {
  switch (dayOfWeek) {
    case SUNDAY:
      return hours.sundayOpen;
    case MONDAY:
      return hours.mondayOpen;
    case TUESDAY:
      return hours.tuesdayOpen;
    case WEDNESDAY:
      return hours.wednesdayOpen;
    case THURSDAY:
      return hours.thursdayOpen;
    case FRIDAY:
      return hours.fridayOpen;
    case SATURDAY:
      return hours.saturdayOpen;
  }
}

function getCloseTime(dayOfWeek: number, hours: StoreHours): string {
  switch (dayOfWeek) {
    case SUNDAY:
      return hours.sundayClose;
    case MONDAY:
      return hours.mondayClose;
    case TUESDAY:
      return hours.tuesdayClose;
    case WEDNESDAY:
      return hours.wednesdayClose;
    case THURSDAY:
      return hours.thursdayClose;
    case FRIDAY:
      return hours.fridayClose;
    case SATURDAY:
      return hours.saturdayClose;
  }
}
