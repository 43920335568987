import {
  orderGuideAdapter,
  orderGuideInitialState,
  OrderGuideState,
} from './order-guide.state';
import { CategorizedMaterialsRecord } from '../../services/order-guide/models/categorized-materials-record';
import { Update } from '@ngrx/entity';
import { OrderGuideRecord } from '../../services/order-guide/models/order-guide-record';
import {
  getClonedOrderGuideCategories,
  getUnassignedCategoryName,
} from './order-guide.utils';
import { move } from '../../../shared/utilities/array-utilities';
import { createReducer, on } from '@ngrx/store';
import { OrderGuideActions } from './order-guide.actions';
import { CategorizedMaterialsUpdateRecord } from '../../services/order-guide/models/categorized-materials-update-record';
import { OrderGuideUpdateRecord } from '../../services/order-guide/models/order-guide-update-record';
import { NaooConstants } from '../../../shared/NaooConstants';

export const orderGuideReducer = createReducer(
  orderGuideInitialState,
  on(
    OrderGuideActions.getOrderGuideSuccess,
    (state, action): OrderGuideState => upsertRecords(state, action.payload),
  ),
  on(
    OrderGuideActions.refreshOrderGuide,
    (): OrderGuideState => orderGuideInitialState,
  ),
  on(
    OrderGuideActions.updateOrderGuideSortBy,
    (state, action): OrderGuideState => ({
      ...state,
      sortBy: action.payload,
    }),
  ),
  on(
    OrderGuideActions.updateOrderGuideSearchText,
    (state, action): OrderGuideState => ({
      ...state,
      searchText: action.searchText,
    }),
  ),
  on(
    OrderGuideActions.addItemToOrderGuideSuccess,
    (state, action): OrderGuideState => addItemToOrderGuide(state, action),
  ),
  on(
    OrderGuideActions.removeItemFromOrderGuideSuccess,
    (state, action): OrderGuideState =>
      removeItemFromOrderGuide(state, action.materialNumber),
  ),
  on(
    OrderGuideActions.createOrderGuideCategory,
    (state, action): OrderGuideState => createOrderGuideCategory(state, action),
  ),
  on(
    OrderGuideActions.renameOrderGuideCategory,
    (state, action): OrderGuideState => renameOrderGuideCategory(state, action),
  ),
  on(
    OrderGuideActions.removeOrderGuideCategory,
    (state, action): OrderGuideState => removeOrderGuideCategory(state, action),
  ),
  on(
    OrderGuideActions.moveOrderGuideCategory,
    (state, action): OrderGuideState => moveOrderGuideCategory(state, action),
  ),
  on(
    OrderGuideActions.moveOrderGuideMaterials,
    (state, action): OrderGuideState => moveOrderGuideMaterials(state, action),
  ),
  on(
    OrderGuideActions.updateOrderGuideUnsavedChanges,
    (state, action): OrderGuideState =>
      updateOrderGuideUnsavedChanges(state, action),
  ),
  on(
    OrderGuideActions.updateOrderGuideSuccess,
    (state, action): OrderGuideState =>
      saveUpdatedOrderGuideChanges(state, action),
  ),
  on(
    OrderGuideActions.removeOrderGuideMaterialsInLocal,
    (state, action): OrderGuideState =>
      removeItemFromOrderGuide(state, action.materialNumber),
  ),
  on(
    OrderGuideActions.removeEmptyCategories,
    (state): OrderGuideState => removeEmptyCategories(state),
  ),
);

export const UNASSIGNED = NaooConstants.unassignedCategory;

function upsertRecords(
  state: OrderGuideState,
  record: OrderGuideRecord,
): OrderGuideState {
  return {
    hasLoaded: true,
    records: orderGuideAdapter.upsertMany(
      record.guideCategories,
      state.records,
    ),
    isOrderGuideEditable: record.orderGuideEditable,
    sortBy: state.sortBy,
    searchText: '',
    hasUnsavedChanges: false,
  };
}

function addItemToOrderGuide(
  state: OrderGuideState,
  action: {
    materialNumber: string;
    categoryName: string;
    categoryId: string;
  },
): OrderGuideState {
  const category: CategorizedMaterialsRecord =
    state.records.entities[action.categoryId];
  const update: CategorizedMaterialsRecord = {
    categoryId: action.categoryId ? action.categoryId : UNASSIGNED,
    categoryName: {
      en: action.categoryName,
      fr: action.categoryName,
    },
    materialNumbers: category
      ? category.materialNumbers.concat(action.materialNumber)
      : [action.materialNumber],
    categoryType: category ? category.categoryType : UNASSIGNED,
  };
  return {
    ...state,
    records: orderGuideAdapter.upsertOne(update, state.records),
  };
}

function removeItemFromOrderGuide(
  state: OrderGuideState,
  materialNumberToDelete: string,
): OrderGuideState {
  const category: CategorizedMaterialsRecord = Object.values(
    state.records.entities,
  ).find((cat) =>
    cat.materialNumbers.some(
      (materialNumber) => materialNumber === materialNumberToDelete,
    ),
  );
  const update: Update<CategorizedMaterialsRecord> = {
    id: category.categoryId,
    changes: {
      materialNumbers: category.materialNumbers.filter(
        (materialNumber) => materialNumber !== materialNumberToDelete,
      ),
    },
  };
  return {
    ...state,
    records: orderGuideAdapter.updateOne(update, state.records),
  };
}

function createOrderGuideCategory(
  state: OrderGuideState,
  action: { categoryName: string },
): OrderGuideState {
  const record = getClonedOrderGuideCategories(state);
  const newCategory: CategorizedMaterialsRecord = {
    categoryId: UNASSIGNED + action.categoryName,
    categoryName: {
      en: action.categoryName,
      fr: action.categoryName,
    },
    materialNumbers: [],
    categoryType: 'Custom',
  };

  if (getExistingUnassignedCategory(record)) {
    record.splice(1, 0, newCategory);
  } else {
    record.splice(0, 0, newCategory);
  }

  return {
    ...state,
    records: orderGuideAdapter.setAll(record, state.records),
  };
}

function renameOrderGuideCategory(
  state: OrderGuideState,
  action: {
    categoryIndex: number;
    categoryName: string;
  },
): OrderGuideState {
  const record = getClonedOrderGuideCategories(state);

  const categoryId = state.records.ids[action.categoryIndex];
  const categoryToBeRenamed: CategorizedMaterialsRecord =
    state.records.entities[categoryId];

  categoryToBeRenamed.categoryId = categoryId.toString();

  categoryToBeRenamed.categoryName.en = action.categoryName;
  categoryToBeRenamed.categoryName.fr = action.categoryName;
  record[action.categoryIndex] = categoryToBeRenamed;
  return {
    ...state,
    records: orderGuideAdapter.setAll(record, state.records),
  };
}

function removeOrderGuideCategory(
  state: OrderGuideState,
  action: { categoryIndex: number },
): OrderGuideState {
  const record = getClonedOrderGuideCategories(state);

  record.splice(action.categoryIndex, 1);

  const deletedCategoryName = state.records.ids[action.categoryIndex];
  return {
    ...state,
    records: orderGuideAdapter.removeOne(
      deletedCategoryName as string,
      state.records,
    ),
  };
}

function moveOrderGuideCategory(
  state: OrderGuideState,
  action: {
    originalCategoryIndex: number;
    newCategoryIndex: number;
  },
): OrderGuideState {
  let record = getClonedOrderGuideCategories(state);

  record = move(record, action.originalCategoryIndex, action.newCategoryIndex);

  return {
    ...state,
    records: orderGuideAdapter.setAll(record, state.records),
  };
}

function moveOrderGuideMaterials(
  state: OrderGuideState,
  action: {
    materialNumber: string;
    newCategoryIndex: number;
    newMaterialIndex: number;
  },
): OrderGuideState {
  const record = getClonedOrderGuideCategories(state);

  const materialNumber = action.materialNumber;
  const originalCategory = record.find((cat) =>
    cat.materialNumbers.includes(materialNumber),
  );
  const originalMaterialIndex =
    originalCategory.materialNumbers.indexOf(materialNumber);
  const originalCategoryIndex = record
    .map((category) => category.categoryId)
    .indexOf(originalCategory.categoryId);

  if (action.newCategoryIndex === -1) {
    record[originalCategoryIndex].materialNumbers.splice(
      originalMaterialIndex,
      1,
    );

    const update: CategorizedMaterialsRecord = {
      categoryId: UNASSIGNED,
      categoryName: {
        en: UNASSIGNED,
        fr: 'Non-assignés',
      },
      materialNumbers: [action.materialNumber],
      categoryType: UNASSIGNED,
    };

    record.push(update);
  } else {
    record[action.newCategoryIndex].materialNumbers.splice(
      action.newMaterialIndex < 0
        ? record[action.newCategoryIndex].materialNumbers.length
        : action.newMaterialIndex,
      0,
      record[originalCategoryIndex].materialNumbers.splice(
        originalMaterialIndex,
        1,
      )[0],
    );
  }
  return {
    ...state,
    records: orderGuideAdapter.setAll(record, state.records),
  };
}

function getExistingUnassignedCategory(
  categorizedMaterialRecords: CategorizedMaterialsRecord[],
) {
  return categorizedMaterialRecords.find(
    (category) => category.categoryName.en === getUnassignedCategoryName().en,
  );
}

function updateOrderGuideUnsavedChanges(
  state: OrderGuideState,
  action: {
    hasUnsavedChanges: boolean;
  },
): OrderGuideState {
  return {
    ...state,
    hasUnsavedChanges: action.hasUnsavedChanges,
  };
}

function saveUpdatedOrderGuideChanges(
  state: OrderGuideState,
  action: {
    payload: OrderGuideUpdateRecord;
  },
): OrderGuideState {
  const records = mapToCategorizedMaterialsRecord(
    action.payload.guideCategories,
  );
  return {
    ...state,
    records: orderGuideAdapter.setAll(records, state.records),
  };
}

function mapToCategorizedMaterialsRecord(
  orderGuideCategories: CategorizedMaterialsUpdateRecord[],
): CategorizedMaterialsRecord[] {
  return orderGuideCategories.map((category) => {
    return {
      categoryId: category.categoryId,
      materialNumbers: category.materialNumbers,
      categoryName: {
        en: category.categoryName,
        fr: category.categoryName,
      },
      categoryType: category.categoryType,
    };
  });
}

function removeEmptyCategories(state: OrderGuideState): OrderGuideState {
  const records = getClonedOrderGuideCategories(state).filter(
    (categoryRecord) => categoryRecord.materialNumbers.length > 0,
  );

  return {
    ...state,
    records: orderGuideAdapter.setAll(records, state.records),
  };
}
