import { Injectable } from '@angular/core';
import { NaooLocalStorage } from '../../storage/local-storage/local-storage.service';
import {
  CartEntityState,
  CartMaterialState,
} from '../../../core/store/cart/cart.state';
import {
  CartLineRecord,
  CartMaterialUpdateRequest,
  CartRecord,
  CartUpdateRequest,
} from '../../../core/services/cart/models/cart-record';
import { formatDate } from '../../utilities/date-utilities';
import {
  MaterialQuantityDetail,
  MaterialQuantityLine,
} from '../../../core/store/shared/shared';

@Injectable({ providedIn: 'root' })
export class CartOfflineStorageService {
  private readonly offlineCartEntity = 'offline-cart-entity';
  private readonly offlineCartEntityTimestamp = 'offline-cart-entity-timestamp';
  private readonly offlineCartEntityCustomerId =
    'offline-cart-entity-customer-id';

  constructor(private naooLocalStorage: NaooLocalStorage) {}

  getCart(): CartEntityState | null {
    const offlineCartString = this.naooLocalStorage.getItem(
      this.offlineCartEntity,
    );
    return offlineCartString ? JSON.parse(offlineCartString) : null;
  }

  getCartTimestamp(): Date | null {
    const offlineCartTimestamp = this.naooLocalStorage.getItem(
      this.offlineCartEntityTimestamp,
    );
    return offlineCartTimestamp ? new Date(offlineCartTimestamp) : null;
  }

  getCartCustomerCompositeId(): string | null {
    const offlineCartCustomerCompositeId = this.naooLocalStorage.getItem(
      this.offlineCartEntityCustomerId,
    );
    return offlineCartCustomerCompositeId
      ? offlineCartCustomerCompositeId
      : null;
  }

  getMaterialQuantityDetailOffline(
    materialNumber: string,
  ): MaterialQuantityDetail | undefined {
    const cartMaterialState =
      this.getCart()?.materials.entities[materialNumber];
    if (!cartMaterialState?.lines) {
      return undefined;
    }

    const lines: MaterialQuantityLine[] = cartMaterialState.lines.ids.map(
      (id, index) => {
        const cartLineState = cartMaterialState.lines.entities[id];

        return {
          index: index + 1,
          uom: cartLineState?.uom,
          quantity: cartLineState?.quantity,
        };
      },
    );

    return {
      materialNumber: materialNumber,
      lines,
      isDeleted: cartMaterialState.isDeleted,
    };
  }

  setCartWithTimestamp(
    cartEntityState: CartEntityState,
    currentCompositeCustomerId: string,
  ) {
    this.naooLocalStorage.setItem(
      this.offlineCartEntity,
      JSON.stringify(cartEntityState),
    );
    this.naooLocalStorage.setItem(
      this.offlineCartEntityTimestamp,
      new Date().toISOString(),
    );
    this.naooLocalStorage.setItem(
      this.offlineCartEntityCustomerId,
      currentCompositeCustomerId,
    );
  }

  deleteCart() {
    this.naooLocalStorage.removeItem(this.offlineCartEntity);
    this.naooLocalStorage.removeItem(this.offlineCartEntityTimestamp);
    this.naooLocalStorage.removeItem(this.offlineCartEntityCustomerId);
  }

  createCartSyncUpdateRequest(
    backendCart: CartRecord,
    offlineCart: CartEntityState,
  ): CartUpdateRequest {
    // zero out backend cart while converting it into a CartUpdateRequest
    const cartSyncRequest: CartUpdateRequest = {
      customerPoNumber: offlineCart.customerPoNumber,
      userLastUpdatedTimestamp: new Date().toISOString(),
      materials: backendCart.materials.map((materialRecord) => ({
        materialNumber: materialRecord.materialNumber,
        lines: [],
        restored: false,
        originTrackingId: materialRecord.originTrackingId,
      })),
      fulfillmentType: offlineCart.fulfillmentType,
      ...(offlineCart.truckFulfillment && {
        truckFulfillment: {
          routeDate: formatDate(offlineCart.truckFulfillment?.routeDate),
          customerArrivalDate: formatDate(
            offlineCart.truckFulfillment?.customerArrivalDate,
          ),
        },
      }),
      ...(offlineCart.storeFulfillment && {
        storeFulfillment: offlineCart.storeFulfillment,
      }),
    };

    (offlineCart.materials.ids as string[]).forEach((offlineMaterialNumber) => {
      const offlineMaterial: CartMaterialState =
        offlineCart.materials.entities[offlineMaterialNumber];

      const cartLines: CartLineRecord[] = offlineMaterial.isDeleted
        ? []
        : Object.values(offlineMaterial.lines.entities).map(
            (cartLineState) => ({
              uom: cartLineState.uom,
              quantity: cartLineState.quantity,
            }),
          );

      const backendMaterial: CartMaterialUpdateRequest =
        cartSyncRequest.materials.find(
          (material) => material.materialNumber === offlineMaterialNumber,
        );

      if (backendMaterial) {
        // overwrite backend cart material with matching material from local storage
        backendMaterial.lines = cartLines;
      } else if (!offlineMaterial.isDeleted) {
        // add material to request that was added while offline and isn't in the backend cart
        cartSyncRequest.materials.push({
          materialNumber: offlineMaterialNumber,
          lines: cartLines,
          restored: offlineMaterial.isRestored,
          originTrackingId: offlineMaterial.originTrackingId,
        });
      }
    });
    return cartSyncRequest;
  }
}
