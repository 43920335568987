import { Dictionary } from '@ngrx/entity';
import {
  CustomGuideRecordState,
  CustomGuidesState,
} from './custom-guide.state';
import { MaterialListStyle } from '../material-row/models/material-row';
import {
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
} from '../material-info/material-info.state';
import { CustomerMaterialRecord } from '../../services/customer-material/model/customer-material-record';
import { CustomGuide } from './models/custom-guide';
import { GroupByType, GuidesContext } from '../../../guides/shared/guides';
import {
  CategorizedGuideMaterials,
  GuidesTransformer,
} from '../guides/guides-transformer';
import { getMaterialNumbersForCategories } from './custom-guide.util';
import { hasMaterialInfoFinishedLoading } from '../material-info/material-info.util';
import { LastOrderedRecordState } from '../last-ordered/last-ordered.state';
import { Localized } from '../../../shared/models/localized';
import { ExportFeatureType } from '../../../shared/models/export/export-properties';
import { ListsAnalyticsConstants } from '../../../lists/lists-analytics.constants';
import { ExportMaterialsInput } from '../../../shared/services/export-materials/models/export-materials';
import { Language } from '../../services/session/models/session-record';
import { NaooConstants } from '../../../shared/NaooConstants';

export class CustomGuideTransformer {
  public static readonly unassignedCategoryName: Localized<string> = {
    en: NaooConstants.unassignedCategory,
    fr: 'Non-assignés',
  };

  public static transformCustomGuide(
    customGuideId: string,
    customGuideState: CustomGuidesState,
    preferredView: MaterialListStyle,
    materialInfoRecords: Dictionary<MaterialInfoRecordState>,
    customerMaterialRecord: CustomerMaterialRecord,
    lastOrderedRecords: Dictionary<LastOrderedRecordState>,
    isOffline: boolean,
    language: Language,
  ): CustomGuide {
    if (!customGuideState.hasLoaded) {
      return undefined;
    }

    const recordState = customGuideState.recordsState.entities[customGuideId];
    if (!recordState || !recordState.record) {
      return undefined;
    }

    const record = recordState.record;
    const materialNumbers = getMaterialNumbersForCategories(record.categories);
    const materialInfos = materialNumbers.map(
      (materialNumber) => materialInfoRecords[materialNumber],
    );

    const hasLoadedMaterialInfos = materialInfos.every((materialInfo) =>
      hasMaterialInfoFinishedLoading(materialInfo),
    );
    if (!hasLoadedMaterialInfos) {
      return undefined;
    }

    const hasLoaded = this.hasMaterialListLoaded(recordState, record.groupBy);
    const categorizedMaterials = hasLoaded
      ? this.transformCategorizedMaterials(
          materialInfoRecords,
          recordState,
          record.groupBy,
        )
      : [];

    const flattenedCategories = GuidesTransformer.flattenCategories(
      categorizedMaterials,
      materialInfoRecords,
      customerMaterialRecord,
      lastOrderedRecords,
      record.sortBy,
      recordState.searchText,
      language,
    );

    return {
      id: record.id,
      header: GuidesTransformer.transformGuideHeader(
        this.transformCategorizedMaterialsForHeader(
          materialInfoRecords,
          recordState,
        ),
        customerMaterialRecord,
        {
          en: record.name,
          fr: record.name,
        },
        recordState.searchText,
        record.parOrderingEnabled,
      ),
      materialListRows: flattenedCategories,
      sideBar: GuidesTransformer.transformGuideSideBar(
        flattenedCategories,
        record.sortBy,
        GuidesContext.CustomGuide,
        record.groupBy,
      ),
      preferredView: GuidesTransformer.getMaterialListStyle(preferredView),
      isOffline,
      hasLoaded,
    };
  }

  public static transformCustomGuideExportMaterialsInput(
    customGuideRecordState: CustomGuideRecordState,
    materialInfoRecords: Dictionary<MaterialInfoRecordState>,
    lastOrderedRecords: Dictionary<LastOrderedRecordState>,
    customerMaterialRecord: CustomerMaterialRecord,
    language: Language,
    isPrint: boolean,
  ): ExportMaterialsInput {
    const categorizedMaterials = this.transformCategorizedMaterials(
      materialInfoRecords,
      customGuideRecordState,
      customGuideRecordState.record.groupBy,
    );
    const guideName: Localized<string> = {
      en: customGuideRecordState.record.name,
      fr: customGuideRecordState.record.name,
    };

    return GuidesTransformer.transformExportMaterialsInput(
      categorizedMaterials,
      materialInfoRecords,
      lastOrderedRecords,
      customerMaterialRecord,
      customGuideRecordState.record.sortBy,
      isPrint
        ? 'EXPORT_MODAL.PRINT_CUSTOM_GUIDE.TITLE'
        : 'EXPORT_MODAL.EXPORT_CUSTOM_GUIDE.TITLE',
      isPrint
        ? ExportFeatureType.CUSTOM_GUIDE_PRINT
        : ExportFeatureType.CUSTOM_GUIDE,
      guideName,
      ListsAnalyticsConstants.customGuideCategory,
      language,
      customGuideRecordState.id,
    );
  }

  private static transformCategorizedMaterialsForHeader(
    materialInfoRecords: Dictionary<MaterialInfoRecordState>,
    recordState: CustomGuideRecordState,
  ): CategorizedGuideMaterials[] {
    return this.transformCategorizedMaterials(
      materialInfoRecords,
      recordState,
      GroupByType.Custom,
    );
  }

  private static transformCategorizedMaterials(
    materialInfoRecords: Dictionary<MaterialInfoRecordState>,
    recordState: CustomGuideRecordState,
    groupBy: GroupByType,
  ): CategorizedGuideMaterials[] {
    let categorizedMaterials: CategorizedGuideMaterials[];
    switch (groupBy) {
      case GroupByType.Custom:
        categorizedMaterials = recordState.record.categories.map(
          (categoryRecord) => {
            return {
              categoryName: categoryRecord.name,
              materialNumbers: categoryRecord.materials.map(
                (material) => material.materialNumber,
              ),
            };
          },
        );
        break;
      case GroupByType.Storage:
        categorizedMaterials =
          recordState.storageAreaCategory?.categories ?? [];
        break;
      case GroupByType.Taxonomy:
        categorizedMaterials = recordState.taxonomyCategory?.categories ?? [];
        break;
      default:
        categorizedMaterials = [];
    }

    return categorizedMaterials.map((category) => {
      const categoryName: Localized<string> = category.categoryName.en
        ? category.categoryName
        : this.unassignedCategoryName;

      return {
        categoryName,
        materialNumbers: category.materialNumbers.filter(
          (materialNumber) =>
            materialInfoRecords[materialNumber]?.status ===
            MaterialInfoRecordStatus.Success,
        ),
      };
    });
  }

  private static hasMaterialListLoaded(
    recordState: CustomGuideRecordState,
    groupBy: GroupByType,
  ): boolean {
    switch (groupBy) {
      case GroupByType.Custom:
        return true;
      case GroupByType.Storage:
        return (
          !!recordState.storageAreaCategory &&
          !!recordState.storageAreaCategory.categories &&
          recordState.storageAreaCategory.hasLoaded
        );
      case GroupByType.Taxonomy:
        return (
          !!recordState.taxonomyCategory &&
          !!recordState.taxonomyCategory.categories &&
          recordState.taxonomyCategory.hasLoaded
        );
      default:
        return false;
    }
  }
}
