import {
  MaterialInfoRecordState,
  MaterialInfoRecordStatus,
} from './material-info.state';
import { MaterialInfo } from '../../../shared/models/material-info';
import { LastOrderedRecordState } from '../last-ordered/last-ordered.state';

export function createMaterialInfoFromMaterialInfoRecordState(
  recordState: MaterialInfoRecordState,
  lastOrderedRecordState?: LastOrderedRecordState,
): MaterialInfo | undefined {
  if (recordState?.status === MaterialInfoRecordStatus.Unavailable) {
    return createUnavailableMaterialInfo(recordState.materialNumber);
  } else if (recordState?.record === undefined) {
    return undefined;
  } else {
    const materialRecord = recordState.record;
    const materialInfo: MaterialInfo = {
      materialNumber: materialRecord.materialNumber,
      brand: materialRecord.brand,
      description: materialRecord.description,
      units: materialRecord.units,
      innerPackSize: materialRecord.innerPackSize,
      baseUom: materialRecord.baseUom,
      baseUomWeight: materialRecord.baseUomWeight,
      isCatchWeight: materialRecord.isCatchWeight,
      pricingPortionPerBaseUom: materialRecord.pricingPortionPerBaseUom,
      pricingPortionUom: materialRecord.pricingPortionUom,
      complimentaryMaterials: materialRecord.complimentaryMaterials,
    };
    if (materialRecord.image) {
      materialInfo.image = materialRecord.image;
    }
    if (
      !!lastOrderedRecordState &&
      !!lastOrderedRecordState.record &&
      !!lastOrderedRecordState.record.lastOrderDate
    ) {
      materialInfo.lastOrderDate = lastOrderedRecordState.record.lastOrderDate;
    }
    return materialInfo;
  }
}

export function createUnavailableMaterialInfo(
  materialNumber: string,
): MaterialInfo {
  return {
    materialNumber: materialNumber,
    brand: {
      id: '',
      en: '',
      fr: '',
    },
    description: {
      en: 'Item Details Unavailable',
      fr: 'Détails du produits non-disponibles',
    },
    innerPackSize: '',
    units: [
      {
        uom: '',
        displayCode: '',
        parentUom: '',
        qtyInParent: '0',
        primaryGtin: '',
        alternateGtins: '',
      },
    ],
    baseUom: '',
    baseUomWeight: {
      net: '0',
      gross: '',
      uom: '',
    },
    isCatchWeight: false,
    isEmpty: true,
    pricingPortionUom: '',
    pricingPortionPerBaseUom: undefined,
  };
}

export function hasMaterialInfoFinishedLoading(
  recordState: MaterialInfoRecordState,
): boolean {
  return (
    !!recordState &&
    [
      MaterialInfoRecordStatus.Error,
      MaterialInfoRecordStatus.Success,
      MaterialInfoRecordStatus.Unavailable,
    ].includes(recordState.status)
  );
}
