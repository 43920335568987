import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  customerNotificationEntryAdapter,
  CustomerNotificationPreferencesState,
  CustomerNotificationsConstants,
  NotificationsState,
} from './customer-notification-preferences.state';
import { selectActiveCustomer } from '../session/session.selectors';
import {
  CurrentSystem,
  Language,
  SessionActiveCustomer,
} from '../../services/session/models/session-record';
import {
  CustomerNotification,
  EditableStatus,
  NotificationLanguage,
  Notifications,
  NotificationsDisplayConstants,
  ReminderDetails,
  ReminderMessage,
} from '../../../account/preferences/shared/models/customer-notifications';
import { selectCurrentUrl } from '../router/router.selectors';
import { NaooConstants } from '../../../shared/NaooConstants';
import { NotificationSubscriptionType } from '../../services/customer-notification-preferences/models/customer-notification-preferences-record';

export const selectCustomerNotificationPreferencesSelector =
  createFeatureSelector<CustomerNotificationPreferencesState>(
    'customerNotifications',
  );

export const selectPreferencesNotifications = createSelector(
  selectCustomerNotificationPreferencesSelector,
  selectCurrentUrl,
  (state, currentUrl) => {
    return {
      emailNotifications:
        state.customerNotificationPreferences?.emailNotifications || [],
      smsNotifications:
        state.customerNotificationPreferences?.smsNotifications || [],
      showModal:
        state.hasError &&
        !state.hasRefreshed &&
        !!currentUrl?.startsWith(NaooConstants.PREFERENCES_PATH),
    };
  },
);

export const selectEmailNotifications = createSelector(
  selectCustomerNotificationPreferencesSelector,
  selectActiveCustomer,
  (state, customer) => transformState(state.email, customer, false),
);

export const selectSmsNotifications = createSelector(
  selectCustomerNotificationPreferencesSelector,
  selectActiveCustomer,
  (state, customer) => transformState(state.sms, customer, true),
);

const selectEmailNotificationEntityState = createSelector(
  selectCustomerNotificationPreferencesSelector,
  (state) => state.email.notifications,
);

const selectSmsNotificationEntityState = createSelector(
  selectCustomerNotificationPreferencesSelector,
  (state) => state.sms.notifications,
);

export const selectShouldDisplayErrorPage = createSelector(
  selectCustomerNotificationPreferencesSelector,
  (state): boolean => state.hasRefreshed && state.hasError,
);

export const selectEmailNotificationStates = createSelector(
  selectEmailNotificationEntityState,
  customerNotificationEntryAdapter.getSelectors().selectAll,
);

export const selectSmsNotificationStates = createSelector(
  selectSmsNotificationEntityState,
  customerNotificationEntryAdapter.getSelectors().selectAll,
);

const SAP_ENABLED_SUBSCRIPTION_TYPES = [
  NotificationSubscriptionType.ORDER_APPROVAL,
  NotificationSubscriptionType.ORDER_CONFIRMATION,
  NotificationSubscriptionType.TRUCK_2HR_CUTOFF,
  NotificationSubscriptionType.ORDER_DELIVERED,
];

const SAP_ENABLED_SMS_SUBSCRIPTION_TYPES = [
  NotificationSubscriptionType.ORDER_APPROVAL,
  NotificationSubscriptionType.ORDER_CONFIRMATION,
  NotificationSubscriptionType.TRUCK_2HR_CUTOFF,
  NotificationSubscriptionType.ORDER_DELIVERED,
  NotificationSubscriptionType.TRUCK_ETA,
  NotificationSubscriptionType.TRUCK_ETA30,
  NotificationSubscriptionType.TRUCK_ETA_ON_DEMAND,
];

const NON_SAP_ENABLED_EMAIL_SUBSCRIPTION_TYPES = [
  NotificationSubscriptionType.ORDER_APPROVAL,
  NotificationSubscriptionType.ORDER_CONFIRMATION,
];

const MYGFS_ENABLED_SMS_SUBSCRIPTION_TYPES = [
  NotificationSubscriptionType.ORDER_APPROVAL,
  NotificationSubscriptionType.TRUCK_ETA,
  NotificationSubscriptionType.TRUCK_ETA30,
  NotificationSubscriptionType.ORDER_CONFIRMATION,
  NotificationSubscriptionType.TRUCK_ETA_ON_DEMAND,
];

const SUPPORTED_REMINDER_MESSAGES = [
  ReminderMessage.MEAT,
  ReminderMessage.ORDER,
  ReminderMessage.CUTOFF,
  ReminderMessage.INVOICE,
];

function transformState(
  state: NotificationsState,
  customer: SessionActiveCustomer,
  isRemindersEnabled: boolean,
): Notifications | undefined {
  if (!state.hasLoaded) {
    return undefined;
  }

  const ordinals = state.notifications.ids as number[];
  const notifications: CustomerNotification[] = ordinals.map((ordinal) => {
    const edittedRecordState = state.notifications.entities[ordinal];
    // We currently only allow one language selection; default to first entry
    const language =
      edittedRecordState.languages[0] === Language.fr
        ? NotificationLanguage.French
        : NotificationLanguage.English;
    const editableStatus = transformEditableStatus(
      state.editingOrdinal,
      edittedRecordState.ordinal,
    );

    return {
      ordinal: edittedRecordState.ordinal,
      name: edittedRecordState.name,
      contactInfo: edittedRecordState.contactInfo,
      language: language,
      editableStatus: editableStatus,
      methodCode: edittedRecordState.type,
      subscriptionTypes: edittedRecordState.subscriptionTypes,
      customerReminders: edittedRecordState.customerReminders,
    };
  });

  if (
    state.editingOrdinal ===
    CustomerNotificationsConstants.newNotificationOrdinal
  ) {
    notifications.unshift({
      ordinal: CustomerNotificationsConstants.newNotificationOrdinal,
      name: '',
      contactInfo: '',
      language: null,
      editableStatus: EditableStatus.Active,
      methodCode: null,
      subscriptionTypes: null,
      customerReminders: null,
    });
  }

  return {
    customer: {
      name: customer.name,
      address: customer.address.line1,
    },
    totalNotifications: ordinals.length,
    customerNotifications: notifications,
    isAddDisabled:
      isAtOrOverLimit(ordinals.length) ||
      state.editingOrdinal !==
        CustomerNotificationsConstants.noneSelectedOrdinal,
    enabledEmailSubscriptionTypes: getAllEnabledEmailSubscriptions(customer),
    enabledSMSSubscriptionTypes: getAllEnabledSMSSubscriptions(customer),
    reminderDetails: isRemindersEnabled ? getReminderDetails(customer) : null,
  };
}

function getAllEnabledEmailSubscriptions(
  customer: SessionActiveCustomer,
): string[] {
  switch (customer.currentSystem) {
    case CurrentSystem.Retalix:
    case CurrentSystem.Mygfs:
      return NON_SAP_ENABLED_EMAIL_SUBSCRIPTION_TYPES;
    case CurrentSystem.Sap:
      return SAP_ENABLED_SUBSCRIPTION_TYPES;
  }
}

function getAllEnabledSMSSubscriptions(
  customer: SessionActiveCustomer,
): string[] {
  switch (customer.currentSystem) {
    case CurrentSystem.Retalix:
      return [];
    case CurrentSystem.Mygfs:
      return MYGFS_ENABLED_SMS_SUBSCRIPTION_TYPES;
    case CurrentSystem.Sap:
      return SAP_ENABLED_SMS_SUBSCRIPTION_TYPES;
  }
}

function getReminderDetails(customer: SessionActiveCustomer): ReminderDetails {
  return {
    customerTimezone: customer.timeZone,
    supportedReminderMessages: SUPPORTED_REMINDER_MESSAGES,
    countryCode: customer.countryCode,
  };
}

function transformEditableStatus(
  editingOrdinal: number,
  ordinal: number,
): EditableStatus {
  if (editingOrdinal === CustomerNotificationsConstants.noneSelectedOrdinal) {
    return EditableStatus.Enabled;
  } else if (editingOrdinal === ordinal) {
    return EditableStatus.Active;
  } else {
    return EditableStatus.Disabled;
  }
}

export function isAtOrOverLimit(notificationCount: number) {
  return (
    notificationCount >= NotificationsDisplayConstants.maxNotificationsCount
  );
}
