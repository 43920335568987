<div class="header-container">
  <div class="back-container">
    <naoo-back-button
      [navigationLink]="getBackNavigationLink()"
    ></naoo-back-button>
    <div class="action-item-container">
      <naoo-action-icon
        actionIcon="print_v2"
        [iconCurrentState]="printIconCurrentState"
        (click)="printMaterialDetails()"
      ></naoo-action-icon>
    </div>
  </div>
  <div class="line-separator"></div>
</div>
<div class="main-container" *ngIf="materialInfo">
  <div
    class="image-and-ordering-container"
    *ngIf="!isAccordionView; else imageAndQuantityTemplate"
  >
    <ng-container *ngTemplateOutlet="imageAndQuantityTemplate"></ng-container>
  </div>
  <div class="product-container" *ngIf="!isAccordionView; else productTemplate">
    <ng-container *ngTemplateOutlet="productTemplate"></ng-container>
  </div>
</div>
<div *ngIf="materialInfo" class="material-recommendation-container">
  <ng-container *ngTemplateOutlet="materialRecommendation"></ng-container>
</div>

<ng-template #imageAndQuantityTemplate>
  <div class="image-container">
    <naoo-material-store-stored-button
      [materialAvailability]="materialDetailsViewModel.materialAvailability"
      [fulfillmentType]="materialDetailsViewModel.cartFulfillmentType"
      [isPdp]="true"
    ></naoo-material-store-stored-button>
    <naoo-image-carousel
      [imageAlt]="materialInfo.description | stringdefaulter"
      [images]="
        materialDetailsViewModel?.materialAdditionalInfo?.images
          | naoocarouselimage: materialInfo.materialNumber
      "
      [isFallbackImage]="isFallbackImage()"
      [isMobile]="isMobile"
      [isAccordionView]="isAccordionView"
      [materialNumber]="
        materialDetailsViewModel?.materialRow?.materialRowOptions.materialNumber
      "
      (select)="onImageSelect($event)"
    ></naoo-image-carousel>
  </div>
  <div class="inventory-container">
    <naoo-inventory
      [materialNumber]="materialInfo.materialNumber"
    ></naoo-inventory>
  </div>
  <div
    *ngIf="materialDetailsViewModel?.orderableComplimentaryMaterials?.length"
    class="complimentary-product-container"
  >
    <span class="section-header">
      {{ 'PRODUCT_DETAILS.COMPATIBLE_WITH' | translate }}
    </span>
    <naoo-complimentary-product
      *ngFor="
        let materialNumber of materialDetailsViewModel.orderableComplimentaryMaterials
          | slice: 0 : 5
      "
      [materialNumber]="materialNumber"
    >
    </naoo-complimentary-product>
  </div>
  <div
    class="attributes-section"
    *ngIf="materialDetailsViewModel.materialAttributes?.length > 0"
  >
    <div class="attributes-header-section">
      <span class="section-header attributes-header">
        {{ 'PRODUCT_DETAILS.PRODUCT_ATTRIBUTES' | translate }}
      </span>
      <button
        attr.aria-label="{{
          'PRODUCT_DETAILS.PRODUCT_ATTRIBUTES_DEFINITIONS' | translate
        }}"
        class="product-attributes-button"
        (click)="openInfoModal()"
      ></button>
    </div>
    <ul class="attribute-item-container">
      <li
        *ngFor="let attr of materialDetailsViewModel.materialAttributes"
        class="attribute-item"
      >
        {{
          ProductDetailsUtilities.getLocalized(
            materialDetailsViewModel?.currentLanguage,
            attr.name
          )
        }}
      </li>
    </ul>
  </div>

  <naoo-nutrition-facts-container
    *ngIf="!isAccordionView && materialDetailsViewModel.isGfsUsCustomerBrand"
    [materialNumber]="materialInfo.materialNumber"
    [nutritionLabel]="materialDetailsViewModel.nutritionLabel"
  ></naoo-nutrition-facts-container>

  <div
    class="nutrition-tables-container"
    *ngIf="
      !isAccordionView &&
      !materialDetailsViewModel.isGfsUsCustomerBrand &&
      ProductDetailsUtilities.hasNutritionInformation(materialDetailsViewModel)
    "
  >
    <naoo-nutrition-details
      class="nutrition-details"
      [nutritionDetails]="
        materialDetailsViewModel.materialAdditionalInfo.nutritionalInfo
      "
    ></naoo-nutrition-details>
  </div>
</ng-template>

<ng-template #productTemplate>
  <div class="print-header">
    <img
      [src]="
        'LOGO.URL'
          | naoobrand: materialDetailsViewModel.customerBrand
          | translate
      "
      [alt]="
        'LOGO.TEXT'
          | naoobrand: materialDetailsViewModel.customerBrand
          | translate
      "
    />
    <div class="title">
      {{ materialDescription | stringdefaulter }}
    </div>
  </div>
  <div class="print-product-details">
    <div>
      <ng-container *ngTemplateOutlet="productDescription"></ng-container>
      <ng-container *ngTemplateOutlet="productPrimaryDetails"></ng-container>
    </div>
    <img
      *ngIf="selectedCarouselImage"
      [src]="selectedCarouselImage.largeImageUrl.src"
      [alt]="materialInfo.description | stringdefaulter"
      (error)="onImageSelect(null)"
      class="print-image"
    />
  </div>

  <ng-container *ngTemplateOutlet="productDescription"></ng-container>
  <ng-container *ngTemplateOutlet="productPrimaryDetails"></ng-container>
  <div class="product-secondary-details">
    <naoo-product-secondary-details
      [viewModel]="materialDetailsViewModel"
      [isAccordionView]="isAccordionView"
    >
    </naoo-product-secondary-details>
    <div *ngIf="materialInfo" class="print-only">
      <ng-container *ngTemplateOutlet="disclaimer"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #disclaimer>
  <p
    class="certifying-body"
    *ngIf="materialDetailsViewModel.materialAdditionalInfo?.halalInfo"
  >
    {{
      'PRODUCT_DETAILS.HALAL_CERTIFYING_BODY'
        | translate
          : {
              certifyingBody:
                materialDetailsViewModel.materialAdditionalInfo.halalInfo,
            }
    }}
  </p>
  <p class="disclaimer">
    <strong>{{ 'DISCLAIMER' | translate }}: </strong>
    <span>{{
      'PRODUCT_DETAILS.DISCLAIMER_TEXT'
        | naoobrand: materialDetailsViewModel.customerBrand
        | translate
    }}</span>
  </p>
</ng-template>

<ng-template #materialRecommendation>
  <div
    *ngIf="materialDetailsViewModel?.recommendedMaterials?.length > 0"
    class="material-recommendation"
  >
    <naoo-material-recommendation
      [recommendedMaterials]="materialDetailsViewModel.recommendedMaterials"
    >
    </naoo-material-recommendation>
  </div>
  <ng-container *ngTemplateOutlet="disclaimer"></ng-container>
</ng-template>

<ng-template #productDescription>
  <div class="description-wrapper">
    <div class="product-description">
      <div class="description-header">
        <h1 class="description">
          {{ materialDescription | stringdefaulter }}
        </h1>
      </div>
      <div class="sub-text-wrapper">
        <span class="sub-text">
          <span class="offering-id"
            ><ng-container
              *ngIf="materialDetailsViewModel?.customerMaterialNumber"
              >{{
                materialDetailsViewModel.customerMaterialNumber
              }}
              : </ng-container
            >#{{ materialInfo?.materialNumber }}
            <span class="gtin" *ngIf="materialDetailsViewModel?.gtin"
              >| {{ 'PRODUCT_DETAILS.GTIN' | translate }}:
              {{ materialDetailsViewModel.gtin }}</span
            >
          </span>
        </span>
        <span class="pack-size" *ngIf="materialInfo?.units?.length > 0">
          |
          {{ materialInfo.units | packsize: materialInfo.innerPackSize }}
        </span>
      </div>
      <div *ngIf="displayLoyaltyPoints" class="print-points">
        <mat-icon class="loyalty-icon" svgIcon="loyalty_icon"></mat-icon>
        {{
          materialRow.materialOrdering.materialOrderingInfos[0].loyaltyPoints
            | naooloyalty
        }}
      </div>
    </div>
    <naoo-material-options-menu
      [materialRowOptions]="materialRow?.materialRowOptions"
      [isMobile]="isMobile"
    >
    </naoo-material-options-menu>
  </div>
</ng-template>

<ng-template #productPrimaryDetails>
  <div class="product-primary-details">
    <div class="product-flag-wrapper">
      <naoo-material-flag-container
        [materialRow]="materialRow"
      ></naoo-material-flag-container>
    </div>
    <div class="ordering-container">
      <div
        [ngClass]="
          materialRow?.materialOrdering
            ? 'ordering-content'
            : 'unorderable-content'
        "
      >
        <naoo-material-ordering
          *ngIf="materialRow?.materialOrdering; else unorderableTemplate"
          [materialOrdering]="materialRow.materialOrdering"
          [materialRowOptions]="materialRow.materialRowOptions"
          [isMobile]="isMobile"
          (viewCommodityDetailsClicked)="openCommodityDocket()"
          [displayGoPointsLogo]="true"
        >
        </naoo-material-ordering>
      </div>
    </div>
    <div
      class="selling-statement selling-paragraphs"
      *ngIf="
        ProductDetailsUtilities.hasDataForSection(
          materialDetailsViewModel,
          ProductDetailsSection.SellingParagraphs
        )
      "
    >
      <p
        *ngFor="
          let paragraph of ProductDetailsUtilities.getDataForSection(
            materialDetailsViewModel,
            ProductDetailsSection.SellingParagraphs
          )
        "
      >
        {{ paragraph }}
      </p>
    </div>
    <div
      class="selling-statement selling-bullets-section"
      *ngIf="
        ProductDetailsUtilities.hasDataForSection(
          materialDetailsViewModel,
          ProductDetailsSection.SellingBullets
        )
      "
    >
      <span class="selling-title section-header">
        {{ 'PRODUCT_DETAILS.PRODUCT_DESCRIPTION' | translate }}
      </span>
      <ul class="selling-bullets">
        <span
          *ngFor="
            let bullet of ProductDetailsUtilities.getDataForSection(
              materialDetailsViewModel,
              ProductDetailsSection.SellingBullets
            )
          "
        >
          <li class="selling-bullet">
            {{ bullet }}
          </li>
        </span>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #unorderableTemplate>
  <ul class="unorderable-list-content">
    <li class="unorderable-content">
      <div class="unorderable-message">
        {{ unorderableText | translate }}
      </div>
    </li>
    <li class="unorderable-content">
      <div class="similar-items" [ngClass]="{ mobile: isMobile }">
        <naoo-material-similar-items
          [isMobile]="isMobile"
          [materialWithSubstitutes]="materialRow"
        >
        </naoo-material-similar-items>
      </div>
    </li>
  </ul>
</ng-template>
