import { createActionGroup, emptyProps } from '@ngrx/store';
import { SmartCartRecommendation } from './smart-cart-recommendations.state';

export const SmartCartRecommendationsActions = createActionGroup({
  source: 'SmartCartRecommendations',
  events: {
    'Load Smart Cart Recommendations': emptyProps,
    'Get Smart Cart Recommendations Success': (
      smartCartRecommendations: SmartCartRecommendation[],
    ) => ({ smartCartRecommendations }),
    'Get Smart Cart Recommendations Error': emptyProps,
  },
});
