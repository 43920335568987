import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CustomerNotification,
  NotificationLanguage,
  Notifications,
} from '../../../account/preferences/shared/models/customer-notifications';
import { CustomerNotificationPreferencesActions } from './customer-notification-preferences.actions';
import { NotificationEntryState } from './customer-notification-preferences.state';
import { Language } from '../../services/session/models/session-record';
import {
  selectEmailNotifications,
  selectShouldDisplayErrorPage,
  selectSmsNotifications,
} from './customer-notification-preferences.selectors';

@Injectable({ providedIn: 'root' })
export class CustomerNotificationPreferencesFacade {
  constructor(private store: Store) {}

  getEmailNotifications(): Observable<Notifications> {
    return this.store.select(selectEmailNotifications);
  }

  getSmsNotifications(): Observable<Notifications> {
    return this.store.select(selectSmsNotifications);
  }

  getShouldDisplayErrorPage(): Observable<boolean> {
    return this.store.select(selectShouldDisplayErrorPage);
  }

  addEmailNotification(): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.addEmailNotification(),
    );
  }

  addSmsNotification(): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.addSmsNotification(),
    );
  }

  editEmailNotification(ordinal: number): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.editEmailNotification(ordinal),
    );
  }

  editSmsNotification(ordinal: number): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.editSmsNotification(ordinal),
    );
  }

  deleteEmailNotification(ordinal: number): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.deleteEmailNotification(ordinal),
    );
  }

  deleteSmsNotification(ordinal: number): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.deleteSmsNotification(ordinal),
    );
  }

  saveEmailNotification(notification: CustomerNotification): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.saveEmailNotification(
        this.transformViewModel(notification),
      ),
    );
  }

  saveSmsNotification(notification: CustomerNotification): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.saveSmsNotification(
        this.transformViewModel(notification),
      ),
    );
  }

  cancelEditEmailNotification(): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.cancelEditEmailNotification(),
    );
  }

  cancelEditSmsNotification(): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.cancelEditSmsNotification(),
    );
  }

  refreshCustomerNotificationPreferences(): void {
    this.store.dispatch(
      CustomerNotificationPreferencesActions.refreshCustomerNotificationPreferences(
        true,
      ),
    );
  }

  private transformViewModel(
    notification: CustomerNotification,
  ): NotificationEntryState {
    const language =
      notification.language === NotificationLanguage.French
        ? Language.fr
        : Language.en;
    return {
      type: notification.methodCode,
      ordinal: notification.ordinal,
      name: notification.name,
      contactInfo: notification.contactInfo,
      languages: [language],
      subscriptionTypes: notification.subscriptionTypes,
      customerReminders: notification.customerReminders,
    };
  }
}
