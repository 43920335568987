import {
  HeaderError,
  ItemDetail,
  LineDetail,
  LineStatusDetail,
  Order,
  OrderItem,
  OrderItemIssues,
  OrderLabelsAndDates,
  QuantityConfirmedIcon,
  SeverityIcon,
  ShipToAddress,
  StatusClass,
} from './models/order-confirmation';
import { CustomerMaterialRecord } from '../../core/services/customer-material/model/customer-material-record';
import {
  OrderConfirmationMaterial,
  OrderConfirmationRecord,
  OrderConfirmationWarning,
  Severity,
} from '../../core/services/order-confirmation/models/order-confirmation-record';
import {
  OrderType,
  RequestedDeliveryType,
} from '../../shared/models/order-type';
import { MaterialInfo } from '../../shared/models/material-info';
import { SessionActiveCustomer } from '../../core/services/session/models/session-record';
import { CustomerMaterialInfo } from '../../core/services/customer-material/model/customer-material-info.model';
import { getCustomerMaterialNumberFromCustomerMaterialInfo } from '../../core/store/customer-material/utilities/customer-material.utilities';
import { DateService } from '../../shared/services/date/date.service';
import { Injectable } from '@angular/core';
import { StoreRecord } from '../../core/services/store/model/store-record';

interface OrderData extends OrderItemIssues {
  headerErrors: HeaderError[];
  order: Order;
  unitTotalsMap: Map<string, number>;
}

interface LineTotals {
  totalQuantity: number;
  estimatedTotal: number;
  orderLines: LineDetail[];
}

@Injectable({
  providedIn: 'root',
})
export class OrderConfirmationTransformationUtil {
  constructor(private dateService: DateService) {}

  transformItemDetail(
    materialInfo: MaterialInfo,
    customerMaterialInfo: CustomerMaterialInfo,
    orderLines: LineDetail[],
    substituteItems?: MaterialInfo[],
  ): ItemDetail {
    const totalDiscountAmount = orderLines.reduce(
      (sum: number, line: LineDetail) => sum + line.discountAmount,
      0,
    );
    const substituteMaterialNumber = orderLines.find((line) => {
      return line.substituteForMaterial != null;
    })?.substituteForMaterial;
    const originalItem = substituteItems?.find(
      (substitute) => substitute?.materialNumber == substituteMaterialNumber,
    );
    let substitutedItem = undefined;
    if (originalItem) {
      substitutedItem = this.transformItemDetail(
        originalItem,
        customerMaterialInfo,
        orderLines,
        [],
      );
    }
    const itemDetail: ItemDetail = {
      id: materialInfo.materialNumber,
      customerMaterialNumber:
        getCustomerMaterialNumberFromCustomerMaterialInfo(customerMaterialInfo),
      description: materialInfo.description,
      brand: {
        en: materialInfo.brand?.en,
        fr: materialInfo.brand?.fr,
      },
      dimensions: {
        innerPackSize: materialInfo.innerPackSize,
        units: materialInfo.units,
        isCatchWeight: materialInfo.isCatchWeight,
        baseUomNetWeight: materialInfo.baseUomWeight.net,
      },
      totalDiscountAmount,
    };
    if (substitutedItem) {
      itemDetail.substitutedItem = substitutedItem;
    }

    return itemDetail;
  }

  groupMaterialLinesByMaterial(
    materials: OrderConfirmationMaterial[],
  ): Map<string, OrderConfirmationMaterial[]> {
    return materials.reduce((acc, materialLine) => {
      const existingMaterialLines = acc.get(materialLine.materialNumber);
      acc.set(materialLine.materialNumber, [
        materialLine,
        ...(existingMaterialLines ?? []),
      ]);
      return acc;
    }, new Map<string, OrderConfirmationMaterial[]>());
  }

  buildShipToAddress(
    orderConfirmationRecord: OrderConfirmationRecord,
    storeRecords: Map<string, StoreRecord>,
  ): ShipToAddress {
    const shippingAddress = orderConfirmationRecord.shippingAddress;
    if (
      OrderType.StoreFulfillment === orderConfirmationRecord.orderType &&
      RequestedDeliveryType.EXPRESS !==
        orderConfirmationRecord.requestedDeliveryType
    ) {
      const storePlantId =
        orderConfirmationRecord.storeFulfillment.storePlantId;
      const storeRecord = storeRecords?.get(storePlantId);
      if (!storeRecord) {
        return null;
      }
      return {
        shipAddress1: storeRecord.address.address1,
        shipAddress2: undefined,
        city: storeRecord.address.city,
        province: storeRecord.address.state,
        zipCode: storeRecord.address.postalCode,
      };
    } else if (shippingAddress) {
      return {
        shipAddress1: shippingAddress.streetAddress ?? '',
        shipAddress2: shippingAddress.streetAddress2 ?? '',
        city: shippingAddress.city ?? '',
        province: shippingAddress.region ?? '',
        zipCode: shippingAddress.postalCode ?? '',
      };
    } else {
      return null;
    }
  }

  transformOrderConfirmationOrderData(
    orderConfirmationRecord: OrderConfirmationRecord,
    materialInfoMap: Map<string, MaterialInfo>,
    storeRecords: Map<string, StoreRecord>,
    customerMaterial: CustomerMaterialRecord,
    customer: SessionActiveCustomer,
  ): OrderData {
    const errorMaterials: OrderItem[] = [];
    const warningMaterials: OrderItem[] = [];
    const unitTotalsMap = new Map<string, number>();
    let headerErrors: HeaderError[] = [];
    let materialLines: OrderConfirmationMaterial[] = [];

    const orderHasError = orderConfirmationRecord.orderWarnings.some(
      OrderConfirmationTransformationUtil.isWarningSeverityError,
    );
    const orderHasWarning = orderConfirmationRecord.orderWarnings.some(
      OrderConfirmationTransformationUtil.isWarningSeverityWarning,
    );

    if (orderHasError || orderHasWarning) {
      headerErrors = this.transformHeaderErrors(orderConfirmationRecord);
    }

    const materialLinesByMaterial = this.groupMaterialLinesByMaterial(
      orderConfirmationRecord.materials,
    );
    materialLines = this.categorizeMaterialLines(
      materialLinesByMaterial,
      unitTotalsMap,
      materialInfoMap,
      customerMaterial,
      errorMaterials,
      warningMaterials,
      materialLines,
    );
    const order = this.transformOrder(
      orderConfirmationRecord,
      materialLines,
      storeRecords,
      materialInfoMap,
      customerMaterial,
      customer,
    );

    return {
      headerErrors,
      errorMaterials,
      warningMaterials,
      order,
      unitTotalsMap,
    };
  }

  private categorizeMaterialLines(
    materialLinesByMaterial: Map<string, OrderConfirmationMaterial[]>,
    unitTotalsMap: Map<string, number>,
    materialInfoMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord,
    errorMaterials: OrderItem[],
    warningMaterials: OrderItem[],
    materialLines: OrderConfirmationMaterial[],
  ) {
    materialLinesByMaterial.forEach((lines, materialNumber) => {
      lines.forEach((line) => {
        const existingQuantity = unitTotalsMap.get(line.uom) ?? 0;
        unitTotalsMap.set(line.uom, existingQuantity + line.quantityConfirmed);
      });
      const materialInfo = materialInfoMap.get(materialNumber);
      const customerMaterialInfo = customerMaterial[materialNumber];
      const substituteItems = lines
        .map((line) => materialInfoMap.get(line.substituteForMaterial))
        .filter((substituteForMaterial) => !!substituteForMaterial);

      if (
        lines.some((line) =>
          OrderConfirmationTransformationUtil.materialLineHasError(line),
        )
      ) {
        errorMaterials.push(
          this.transformOrderItem(
            lines,
            materialInfo,
            customerMaterialInfo,
            substituteItems,
          ),
        );
      } else if (
        lines.some((line) =>
          OrderConfirmationTransformationUtil.materialLineHasWarning(line),
        )
      ) {
        const warningMaterial = this.transformOrderItem(
          lines,
          materialInfo,
          customerMaterialInfo,
          substituteItems,
        );
        warningMaterials.push(warningMaterial);
      } else {
        materialLines = materialLines.concat(lines);
      }
    });
    return materialLines;
  }

  private transformOrder(
    orderConfirmationRecord: OrderConfirmationRecord,
    materialLines: OrderConfirmationMaterial[],
    storeRecords: Map<string, StoreRecord>,
    materialInfoMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord,
    customer: SessionActiveCustomer,
  ): Order {
    const items: OrderItem[] = [];
    const processedMaterials = this.processAndTransformMaterials(
      materialLines,
      materialInfoMap,
      customerMaterial,
      orderConfirmationRecord.orderType,
      items,
    );
    const {
      requestedCustomerArrivalDate,
      orderNumber,
      poNumber,
      storeFulfillment,
      orderType,
      totalExtendedPrice,
      totalShipping,
      totalPrice,
    } = orderConfirmationRecord;

    const shipping = totalShipping ? totalShipping : 0;
    return {
      items,
      subTotalCost: totalExtendedPrice,
      shippingCost: shipping,
      shipToAddress: this.buildShipToAddress(
        orderConfirmationRecord,
        storeRecords,
      ),
      estimatedTotal: totalPrice,
      totalQuantity: processedMaterials.totalQuantity,
      requestedCustomerArrivalDate,
      orderNumber,
      poNumber,
      orderType,
      totalExtendedPrice,
      totalShipping: shipping,
      requestedPickupTimestamp: storeFulfillment?.requestedPickupTimestamp,
      deliveryWindowStartTimestamp:
        storeFulfillment?.deliveryWindowStartTimestamp,
      labelsAndDates: this.getLabelAndDateFields(
        orderConfirmationRecord,
        customer.timeZone,
      ),
    };
  }

  private processAndTransformMaterials(
    materialLines: OrderConfirmationMaterial[],
    materialInfoMap: Map<string, MaterialInfo>,
    customerMaterial: CustomerMaterialRecord,
    orderType: OrderType,
    items: OrderItem[],
  ): LineTotals {
    const materialLinesByMaterial =
      this.groupMaterialLinesByMaterial(materialLines);
    let totalQuantity = 0;
    let estimatedTotal = 0;

    materialLinesByMaterial.forEach((lines, materialNumber) => {
      const materialInfo = materialInfoMap.get(materialNumber);
      const customerMaterialInfo = customerMaterial[materialNumber];
      const lineTotals = this.transformMaterialLines(
        lines,
        totalQuantity,
        estimatedTotal,
        orderType,
        materialInfo,
      );
      totalQuantity = lineTotals.totalQuantity;
      estimatedTotal = lineTotals.estimatedTotal;
      const orderLines = lineTotals.orderLines;
      const itemDetail: ItemDetail = this.transformItemDetail(
        materialInfo,
        customerMaterialInfo,
        orderLines,
      );

      orderLines.forEach((line) => {
        const substitutedInfo = materialInfoMap.get(line.substituteForMaterial);
        if (substitutedInfo) {
          itemDetail.substitutedItem = this.transformItemDetail(
            substitutedInfo,
            customerMaterialInfo,
            orderLines,
          );
        }
      });

      items.push({ orderLines, itemDetail });
    });
    return { totalQuantity, estimatedTotal, orderLines: [] };
  }

  private transformMaterialLines(
    lines: OrderConfirmationMaterial[],
    totalQuantity: number,
    estimatedTotal: number,
    orderType: OrderType,
    materialInfo: MaterialInfo,
  ): LineTotals {
    const orderLines: LineDetail[] = lines.map((materialLine) => {
      if (OrderType.DropShip === orderType) {
        totalQuantity += materialLine.quantityOrdered;
      } else {
        totalQuantity += materialLine.quantityConfirmed;
      }
      estimatedTotal += materialLine.totalLinePrice;
      const catchWeightUom = materialLine.catchweightIndicator
        ? materialInfo.baseUomWeight?.uom
        : materialLine.catchWeightUom;
      const price = materialLine.catchweightIndicator
        ? materialLine.catchWeightPrice
        : materialLine.price;
      const lineSeverity = this.transformLineSeverity(materialLine);
      const displayCode = materialInfo.units.find(
        (unit) => unit.uom === materialLine.uom,
      )?.displayCode;

      const lineDetail: LineDetail = {
        uom: materialLine.uom,
        displayCode,
        catchWeightUom,
        price,
        quantityOrdered: materialLine.quantityOrdered,
        totalLinePrice: materialLine.totalLinePrice,
        quantityConfirmed: materialLine.quantityConfirmed,
        lineSeverity,
        quantityConfirmedIcon:
          OrderConfirmationTransformationUtil.transformQuantityConfirmedIcon(
            lineSeverity,
          ),
        quantityConfirmedClass:
          OrderConfirmationTransformationUtil.transformStatusClass(
            lineSeverity,
          ),
        discountAmount: materialLine.totalLineDiscountAmount,
      };
      if (materialLine.substituteForMaterial) {
        lineDetail.substituteForMaterial = materialLine.substituteForMaterial;
      }
      return lineDetail;
    });
    return { totalQuantity, estimatedTotal, orderLines };
  }

  private transformOrderItem(
    materialLines: OrderConfirmationMaterial[],
    materialInfo: MaterialInfo,
    customerMaterialInfo: CustomerMaterialInfo,
    substituteItems: MaterialInfo[],
  ): OrderItem {
    const orderLines = materialLines.map((materialLine) =>
      this.transformOrderLineDetail(materialLine, materialInfo),
    );
    const itemDetail = this.transformItemDetail(
      materialInfo,
      customerMaterialInfo,
      orderLines,
      substituteItems,
    );

    return { orderLines, itemDetail };
  }

  private transformOrderLineDetail(
    materialLine: OrderConfirmationMaterial,
    materialInfo: MaterialInfo,
  ): LineDetail {
    const {
      uom,
      price,
      quantityOrdered,
      totalLinePrice,
      quantityConfirmed,
      totalLineDiscountAmount,
    } = materialLine;
    const catchWeightUom = materialLine.catchWeightUom;

    const lineSeverity = this.transformLineSeverity(materialLine);
    const lineStatusDetails = this.transformLineStatusDetails(materialLine);
    const quantityConfirmedIcon =
      OrderConfirmationTransformationUtil.transformQuantityConfirmedIcon(
        lineSeverity,
      );
    const quantityConfirmedClass =
      OrderConfirmationTransformationUtil.transformStatusClass(lineSeverity);
    const displayCode = materialInfo.units.find(
      (unit) => unit.uom === materialLine.uom,
    )?.displayCode;

    const lineDetail: LineDetail = {
      uom,
      displayCode,
      catchWeightUom,
      price,
      quantityOrdered,
      totalLinePrice,
      quantityConfirmed,
      lineSeverity,
      quantityConfirmedIcon,
      quantityConfirmedClass,
      lineStatusDetails,
      discountAmount: totalLineDiscountAmount,
    };

    if (materialLine.substituteForMaterial) {
      lineDetail.substituteForMaterial = materialLine.substituteForMaterial;
    }

    return lineDetail;
  }

  private transformHeaderErrors(order: OrderConfirmationRecord): HeaderError[] {
    return order.orderWarnings.map((warning) => {
      return {
        primaryText: warning.shortDescription,
        secondaryText: undefined,
      };
    });
  }

  private transformLineStatusDetails(
    materialLine: OrderConfirmationMaterial,
  ): LineStatusDetail[] {
    const hasLineWarnings = materialLine.lineWarnings.length > 0;
    if (!hasLineWarnings) {
      return [
        {
          lineStatusClass:
            OrderConfirmationTransformationUtil.transformStatusClass(
              materialLine.lineStatusSeverity,
            ),
          icon: OrderConfirmationTransformationUtil.transformSeverityIcon(
            materialLine.lineStatusSeverity,
          ),
          primaryLineStatus: materialLine.lineStatusDescription,
          secondaryLineStatus: materialLine.lineStatusErrorDescription,
        },
      ];
    }
    const uniqueWarning = new Map();
    materialLine.lineWarnings.forEach((lineWarning) => {
      uniqueWarning.set(JSON.stringify(lineWarning.shortDescription), {
        lineStatusClass:
          OrderConfirmationTransformationUtil.transformStatusClass(
            lineWarning.severity,
          ),
        icon: OrderConfirmationTransformationUtil.transformSeverityIcon(
          lineWarning.severity,
        ),
        primaryLineStatus: lineWarning.shortDescription,
        secondaryLineStatus: materialLine.lineStatusErrorDescription,
      });
    });
    return Array.from(uniqueWarning.values());
  }

  private transformLineSeverity(materialLine: OrderConfirmationMaterial) {
    if (
      OrderConfirmationTransformationUtil.materialLineHasError(materialLine)
    ) {
      return Severity.Error;
    } else if (
      OrderConfirmationTransformationUtil.materialLineHasWarning(materialLine)
    ) {
      return Severity.Warning;
    }
    return Severity.Success;
  }

  private static transformQuantityConfirmedIcon(
    severity: Severity,
  ): QuantityConfirmedIcon {
    if (Severity.Error === severity) {
      return QuantityConfirmedIcon.Error;
    } else if (Severity.Warning === severity) {
      return QuantityConfirmedIcon.Warning;
    }
    return QuantityConfirmedIcon.None;
  }

  private static transformStatusClass(severity: Severity): StatusClass {
    if (Severity.Error === severity) {
      return StatusClass.Error;
    } else if (Severity.Warning === severity) {
      return StatusClass.Warning;
    }
    return StatusClass.None;
  }

  private static transformSeverityIcon(severity: Severity): SeverityIcon {
    if (Severity.Error === severity) {
      return SeverityIcon.Error;
    } else if (Severity.Warning === severity) {
      return SeverityIcon.Warning;
    }
    return SeverityIcon.None;
  }

  private static materialLineHasError(
    materialLine: OrderConfirmationMaterial,
  ): boolean {
    return (
      materialLine.lineWarnings.some(
        OrderConfirmationTransformationUtil.isWarningSeverityError,
      ) || Severity.Error === materialLine.lineStatusSeverity
    );
  }

  private static materialLineHasWarning(
    materialLine: OrderConfirmationMaterial,
  ): boolean {
    return (
      materialLine.lineWarnings.some(
        OrderConfirmationTransformationUtil.isWarningSeverityWarning,
      ) || Severity.Warning === materialLine.lineStatusSeverity
    );
  }

  private static isWarningSeverityError(
    warning: OrderConfirmationWarning,
  ): boolean {
    return Severity.Error === warning.severity;
  }

  private static isWarningSeverityWarning(
    warning: OrderConfirmationWarning,
  ): boolean {
    return Severity.Warning === warning.severity;
  }

  public getLabelAndDateFields(
    orderConfirmationRecord: OrderConfirmationRecord,
    timeZone: string,
  ): OrderLabelsAndDates {
    if (
      OrderType.StoreFulfillment === orderConfirmationRecord.orderType &&
      RequestedDeliveryType.EXPRESS ===
        orderConfirmationRecord.requestedDeliveryType
    ) {
      return {
        orderTitle: 'ORDER_CONFIRMATION.EXPRESS_ORDERS.TITLE',
        splitOrderTitle: 'ORDER_CONFIRMATION.EXPRESS_ORDERS.TITLE',
        deliveryDateLabel: 'ORDER_CONFIRMATION.ESTIMATED_DELIVERY_DATE',
        deliveryDate: this.dateService.getLocalizedDateString(
          orderConfirmationRecord.storeFulfillment
            ?.deliveryWindowStartTimestamp,
          'weekdayMonthDay',
          timeZone,
          false,
          OrderConfirmationTransformationUtil.addCommaIfNeeded(
            orderConfirmationRecord,
          ),
        ),
        deliveryTime: this.dateService.getLocalizedTimeRange(
          orderConfirmationRecord.storeFulfillment
            ?.deliveryWindowStartTimestamp,
          orderConfirmationRecord.storeFulfillment?.deliveryWindowEndTimestamp,
          timeZone,
        ),
      };
    } else if (
      OrderType.StoreFulfillment === orderConfirmationRecord.orderType
    ) {
      return {
        splitOrderTitle: 'ORDER_CONFIRMATION.ISPU_ORDERS.TITLE',
        orderTitle: 'ORDER_CONFIRMATION.ISPU_ORDERS.TITLE',
        deliveryDateLabel: 'ORDER_CONFIRMATION.PICKUP_AFTER',
        deliveryDate: this.dateService.getLocalizedDateString(
          orderConfirmationRecord.storeFulfillment?.requestedPickupTimestamp,
          'weekdayMonthDay',
          timeZone,
          false,
          ',',
        ),
        deliveryTime: this.dateService.getLocalizedDateString(
          orderConfirmationRecord.storeFulfillment?.requestedPickupTimestamp,
          'time',
          timeZone,
        ),
      };
    } else {
      return {
        orderTitle: 'ORDER_CONFIRMATION.STANDARD_ORDERS.TITLE',
        splitOrderTitle: 'ORDER_CONFIRMATION.STANDARD_ORDERS.TITLE',
        deliveryDateLabel: 'ORDER_CONFIRMATION.SHIP_DATE',
        deliveryDate: this.dateService.getLocalizedDateString(
          orderConfirmationRecord.requestedCustomerArrivalDate,
          'weekdayMonthDay',
          timeZone,
          false,
        ),
        deliveryTime: null,
      };
    }
  }

  private static addCommaIfNeeded(
    orderConfirmationRecord: OrderConfirmationRecord,
  ): string {
    return orderConfirmationRecord.storeFulfillment
      ?.deliveryWindowStartTimestamp === undefined &&
      orderConfirmationRecord.storeFulfillment?.deliveryWindowEndTimestamp ===
        undefined
      ? ''
      : ',';
  }
}
