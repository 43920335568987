import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ErrorHandler, NgModule, Provider } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderModule } from './header/header.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { Angulartics2GoogleTagManager, Angulartics2Module } from 'angulartics2';
import { NaooErrorHandler } from './shared/error-handler/NaooErrorHandler';
import { NaooErrorUtils } from './shared/error-handler/NaooErrorUtils';
import { NaooHttpClient } from './shared/http-client/naoo-http-client';
import { ErrorStateModule } from './shared/error-state/error-state.module';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { CoreModule } from './core/core.module';
import { registerLocaleData } from '@angular/common';
import localeFrCa from '@angular/common/locales/fr-CA';
import localeEnCa from '@angular/common/locales/en-CA';
import { DeliveryDatePickerModule } from './shared/delivery-date-picker/delivery-date-picker.module';
import { SCROLL_CONTAINER_PROVIDER } from './shared/services/scrollable-content/scrollable-content.service';
import { MaterialComparisonModule } from './material-comparison/material-comparison.module';
import { NaooHammerGestureConfig } from './hammer-gesture-config';
import { httpInterceptorProviders } from './shared/interceptors';
import {
  RouterState,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './core/store/router/router-state-serializer';
import { MatIconModule } from '@angular/material/icon';
import { PreloadImagesComponent } from './shared/preload-images/preload-images.component';
import { OfflineModule } from './offline/offline.module';
import { InlineTranslateLoader } from './shared/inline-translate-loader/inline-translate-loader';
import { CommodityDocketModule } from './shared/commodity-docket/commodity-docket.module';
import { OrderMethodModalModule } from './shared/modals/order-method-modal/order-method-modal.module';
import { CartTransferComponent } from './cart/cart-transfer/cart-transfer.component';
import { BulkAddModalModule } from './shared/bulk-add-modal/bulk-add-modal.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ScrollingModule } from '@angular/cdk/scrolling';

const providers: Provider[] = [
  TranslateService,
  Angulartics2GoogleTagManager,
  { provide: ErrorHandler, useClass: NaooErrorHandler },
  { provide: NaooErrorUtils, useClass: NaooErrorUtils },
  { provide: HttpClient, useClass: NaooHttpClient },
  { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
  httpInterceptorProviders,
  {
    provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
    useValue: {
      appearance: 'legacy', // This changed in Material 7 so we need to pin it to the old style
    },
  },
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic',
    },
  },
  {
    provide: HAMMER_GESTURE_CONFIG,
    useClass: NaooHammerGestureConfig,
  },
  { provide: Window, useValue: window },
  SCROLL_CONTAINER_PROVIDER,
];

@NgModule({
  declarations: [AppComponent, PreloadImagesComponent, CartTransferComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BulkAddModalModule,
    CoreModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    HammerModule,
    HeaderModule,
    MatIconModule,
    ErrorStateModule,
    DeliveryDatePickerModule,
    OrderMethodModalModule,
    MaterialComparisonModule,
    CommodityDocketModule,
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    Angulartics2Module.forRoot(),
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: InlineTranslateLoader },
    }),
    OfflineModule,
    ScrollingModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi()), ...providers],
  bootstrap: [AppComponent],
})
export class AppModule {}

registerLocaleData(localeFrCa, localeEnCa);
