import {
  CategorizedMaterialNumbers,
  customGuideAdapter,
  customGuideInitialState,
  CustomGuideRecordState,
  CustomGuidesState,
} from './custom-guide.state';
import { CustomGuideActions } from './custom-guide.actions';
import {
  CustomGuideCategoryRecord,
  CustomGuideMaterialRecord,
  CustomGuideRecord,
} from '../../services/custom-guide/model/custom-guide-record';
import {
  cloneCustomGuideRecord,
  getCategoryIndexForMaterial,
  getCustomGuideCategorizedRecordState,
  getMaterialIndexForMaterial,
  getMaterialRecordFromCustomGuideRecord,
} from './custom-guide.util';
import { move } from '../../../shared/utilities/array-utilities';
import { SortByType, GroupByType } from 'src/app/guides/shared/guides';
import { createReducer, on } from '@ngrx/store';

export const customGuideReducer = createReducer(
  customGuideInitialState,
  on(
    CustomGuideActions.getAllCustomGuidesSuccess,
    (state, action): CustomGuidesState =>
      getAllCustomGuideSuccess(state, action),
  ),
  on(
    CustomGuideActions.getAllCustomGuidesFailure,
    (): CustomGuidesState => getAllCustomGuideFailure(),
  ),
  on(
    CustomGuideActions.refreshAllCustomGuides,
    (): CustomGuidesState => customGuideInitialState,
  ),
  on(
    CustomGuideActions.importCustomGuideSuccess,
    CustomGuideActions.createCategorizedCustomGuideSuccess,
    (state, action): CustomGuidesState =>
      createCategorizedCustomGuideSuccess(state, action),
  ),
  on(
    CustomGuideActions.deleteCustomGuideSuccess,
    (state, action): CustomGuidesState =>
      deleteCustomGuideSuccess(state, action),
  ),
  on(
    CustomGuideActions.renameCustomGuide,
    (state, action): CustomGuidesState => renameCustomGuide(state, action),
  ),
  on(
    CustomGuideActions.updateCustomGuideGroupBy,
    (state, action): CustomGuidesState =>
      updateCustomGuideGroupBy(state, action),
  ),
  on(
    CustomGuideActions.updateCustomGuideSortBy,
    (state, action): CustomGuidesState =>
      updateCustomGuideSortBy(state, action),
  ),
  on(
    CustomGuideActions.updateCustomGuideSearchText,
    (state, action): CustomGuidesState =>
      updateCustomGuideSearchText(state, action),
  ),
  on(
    CustomGuideActions.clearInventoryQuantities,
    (state, action): CustomGuidesState =>
      clearInventoryQuantities(state, action),
  ),
  on(
    CustomGuideActions.createCustomGuideCategory,
    (state, action): CustomGuidesState =>
      createCustomGuideCategory(state, action),
  ),
  on(
    CustomGuideActions.renameCustomGuideCategory,
    (state, action): CustomGuidesState =>
      renameCustomGuideCategory(state, action),
  ),
  on(
    CustomGuideActions.removeCustomGuideCategory,
    (state, action): CustomGuidesState =>
      removeCustomGuideCategory(state, action),
  ),
  on(
    CustomGuideActions.moveCustomGuideCategory,
    (state, action): CustomGuidesState =>
      moveCustomGuideCategory(state, action),
  ),
  on(
    CustomGuideActions.addCustomGuideMaterialsSuccess,
    (state, action): CustomGuidesState =>
      addCustomGuideMaterialsSuccess(state, action),
  ),
  on(
    CustomGuideActions.removeCustomGuideMaterials,
    (state, action): CustomGuidesState =>
      removeCustomGuideMaterials(state, action),
  ),
  on(
    CustomGuideActions.moveCustomGuideMaterials,
    (state, action): CustomGuidesState =>
      moveCustomGuideMaterials(state, action),
  ),
  on(
    CustomGuideActions.updateParQuantity,
    (state, action): CustomGuidesState => updateParQuantity(state, action),
  ),
  on(
    CustomGuideActions.updateInventoryQuantity,
    (state, action): CustomGuidesState =>
      updateInventoryQuantity(state, action),
  ),
  on(
    CustomGuideActions.clearInventoryQuantities,
    (state, action): CustomGuidesState =>
      clearInventoryQuantities(state, action),
  ),
  on(
    CustomGuideActions.toggleParOrdering,
    (state, action): CustomGuidesState => toggleParOrdering(state, action),
  ),
  on(
    CustomGuideActions.getCategorizedCustomGuideSuccess,
    (state, action): CustomGuidesState =>
      getCategorizedCustomGuideSuccess(state, action),
  ),
  on(
    CustomGuideActions.getCategorizedCustomGuideFailure,
    (state, action): CustomGuidesState =>
      getCategorizedCustomGuideFailure(state, action),
  ),
  on(
    CustomGuideActions.clearCategorizedCustomGuide,
    (state, action): CustomGuidesState =>
      clearCategorizedCustomGuide(state, action),
  ),
  on(
    CustomGuideActions.getAllCustomGuides,
    CustomGuideActions.createCustomGuide,
    CustomGuideActions.createCategorizedCustomGuide,
    CustomGuideActions.duplicateCustomGuide,
    CustomGuideActions.deleteCustomGuide,
    CustomGuideActions.updateCustomGuide,
    CustomGuideActions.updateCustomGuideSuccess,
    CustomGuideActions.addCustomGuideMaterials,
    CustomGuideActions.getCategorizedCustomGuide,
    (state): CustomGuidesState => state,
  ),
);

function getAllCustomGuideSuccess(
  state: CustomGuidesState,
  action: {
    records: CustomGuideRecord[];
  },
): CustomGuidesState {
  return {
    ...state,
    recordsState: customGuideAdapter.setAll(
      action.records.map((record) => {
        return {
          id: record.id,
          searchText: '',
          record: record,
        };
      }),
      state.recordsState,
    ),
    hasLoaded: true,
  };
}

function getAllCustomGuideFailure(): CustomGuidesState {
  return {
    recordsState: customGuideAdapter.getInitialState(),
    hasLoaded: true,
  };
}

function createCategorizedCustomGuideSuccess(
  state: CustomGuidesState,
  action: {
    record: CustomGuideRecord;
  },
): CustomGuidesState {
  return {
    ...state,
    recordsState: customGuideAdapter.upsertOne(
      {
        id: action.record.id,
        searchText: '',
        record: action.record,
      },
      state.recordsState,
    ),
  };
}

function deleteCustomGuideSuccess(
  state: CustomGuidesState,
  action: {
    id: string;
    name: string;
  },
): CustomGuidesState {
  return {
    ...state,
    recordsState: customGuideAdapter.removeOne(action.id, state.recordsState),
  };
}

function renameCustomGuide(
  state: CustomGuidesState,
  action: {
    id: string;
    name: string;
  },
): CustomGuidesState {
  const change: Partial<CustomGuideRecord> = {
    name: action.name,
  };
  return updateCustomGuideRecord(state, action.id, change);
}

function updateCustomGuideGroupBy(
  state: CustomGuidesState,
  action: {
    id: string;
    groupBy: GroupByType;
  },
): CustomGuidesState {
  const record = state.recordsState.entities[action.id].record;

  let sortBy: SortByType;
  if (
    action.groupBy !== GroupByType.Custom &&
    record.sortBy === SortByType.Custom
  ) {
    sortBy = SortByType.Description;
  } else if (action.groupBy === GroupByType.Custom) {
    sortBy = SortByType.Custom;
  } else {
    sortBy = record.sortBy;
  }

  const change: Partial<CustomGuideRecord> = {
    groupBy: action.groupBy,
    sortBy,
  };
  return updateCustomGuideRecord(state, action.id, change);
}

function updateCustomGuideSortBy(
  state: CustomGuidesState,
  action: {
    id: string;
    sortBy: SortByType;
  },
): CustomGuidesState {
  const change: Partial<CustomGuideRecord> = {
    sortBy: action.sortBy,
  };
  return updateCustomGuideRecord(state, action.id, change);
}

function updateCustomGuideSearchText(
  state: CustomGuidesState,
  action: {
    id: string;
    searchText: string;
  },
): CustomGuidesState {
  const change: Partial<CustomGuideRecordState> = {
    searchText: action.searchText,
  };
  return updateCustomGuideRecordState(state, action.id, change);
}

function createCustomGuideCategory(
  state: CustomGuidesState,
  action: {
    id: string;
    categoryName: string;
  },
): CustomGuidesState {
  const record = cloneCustomGuideRecord(
    state.recordsState.entities[action.id].record,
  );
  const newCategory: CustomGuideCategoryRecord = {
    name: {
      en: action.categoryName,
      fr: action.categoryName,
    },
    materials: [],
  };
  record.categories.splice(1, 0, newCategory);

  return updateCustomGuideRecord(state, action.id, record);
}

function renameCustomGuideCategory(
  state: CustomGuidesState,
  action: {
    id: string;
    categoryIndex: number;
    categoryName: string;
  },
): CustomGuidesState {
  const record = cloneCustomGuideRecord(
    state.recordsState.entities[action.id].record,
  );
  record.categories[action.categoryIndex].name.en = action.categoryName;
  record.categories[action.categoryIndex].name.fr = action.categoryName;

  return updateCustomGuideRecord(state, action.id, record);
}

function removeCustomGuideCategory(
  state: CustomGuidesState,
  action: {
    id: string;
    categoryIndex: number;
  },
): CustomGuidesState {
  const recordState = state.recordsState.entities[action.id];
  const record = cloneCustomGuideRecord(recordState.record);

  record.categories.splice(action.categoryIndex, 1);

  const changes: Partial<CustomGuideRecordState> = {
    record,
    storageAreaCategory: getCustomGuideCategorizedRecordState(
      recordState.storageAreaCategory,
      record,
    ),
    taxonomyCategory: getCustomGuideCategorizedRecordState(
      recordState.taxonomyCategory,
      record,
    ),
  };

  return updateCustomGuideRecordState(state, action.id, changes);
}

function moveCustomGuideCategory(
  state: CustomGuidesState,
  action: {
    id: string;
    originalCategoryIndex: number;
    newCategoryIndex: number;
  },
): CustomGuidesState {
  const record = cloneCustomGuideRecord(
    state.recordsState.entities[action.id].record,
  );
  record.categories = move(
    record.categories,
    action.originalCategoryIndex,
    action.newCategoryIndex,
  );

  return updateCustomGuideRecord(state, action.id, record);
}

function addCustomGuideMaterialsSuccess(
  state: CustomGuidesState,
  action: {
    id: string;
    materialNumbers: string[];
  },
): CustomGuidesState {
  const record = cloneCustomGuideRecord(
    state.recordsState.entities[action.id].record,
  );
  const materials: CustomGuideMaterialRecord[] = action.materialNumbers.map(
    (materialNumber) => {
      return {
        materialNumber: materialNumber,
        parLines: [],
      };
    },
  );
  record.categories[0].materials.unshift(...materials);

  return updateCustomGuideRecord(state, action.id, record);
}

function removeCustomGuideMaterials(
  state: CustomGuidesState,
  action: {
    id: string;
    materialNumbers: string[];
  },
): CustomGuidesState {
  const recordState = state.recordsState.entities[action.id];
  const record = cloneCustomGuideRecord(recordState.record);

  record.categories = getCategoryRecordsWithRemovedMaterials(
    record.categories,
    action.materialNumbers,
  );

  const changes: Partial<CustomGuideRecordState> = {
    record,
    storageAreaCategory: getCustomGuideCategorizedRecordState(
      recordState.storageAreaCategory,
      record,
    ),
    taxonomyCategory: getCustomGuideCategorizedRecordState(
      recordState.taxonomyCategory,
      record,
    ),
  };

  return updateCustomGuideRecordState(state, action.id, changes);
}

function moveCustomGuideMaterials(
  state: CustomGuidesState,
  action: {
    id: string;
    materialNumber: string;
    newCategoryIndex: number;
    newMaterialIndex?: number;
  },
): CustomGuidesState {
  const record = cloneCustomGuideRecord(
    state.recordsState.entities[action.id].record,
  );
  const originalCategoryIndex = getCategoryIndexForMaterial(
    record,
    action.materialNumber,
  );
  const originalMaterialIndex = getMaterialIndexForMaterial(
    record.categories[originalCategoryIndex],
    action.materialNumber,
  );

  record.categories[action.newCategoryIndex].materials.splice(
    action.newMaterialIndex ? action.newMaterialIndex : 0,
    0,
    record.categories[originalCategoryIndex].materials.splice(
      originalMaterialIndex,
      1,
    )[0],
  );

  return updateCustomGuideRecord(state, action.id, record);
}

function updateParQuantity(
  state: CustomGuidesState,
  action: { id: string; materialNumber: string; uom: string; quantity: number },
): CustomGuidesState {
  const record = cloneCustomGuideRecord(
    state.recordsState.entities[action.id].record,
  );
  const material = getMaterialRecordFromCustomGuideRecord(
    record,
    action.materialNumber,
  );
  const existingParLine = material.parLines.find(
    (parLine) => parLine.uom === action.uom,
  );

  if (existingParLine) {
    existingParLine.parQuantity = action.quantity;
  } else {
    material.parLines.push({
      parQuantity: action.quantity,
      inventoryQuantity: 0,
      uom: action.uom,
    });
  }

  return updateCustomGuideRecord(state, action.id, record);
}

function updateInventoryQuantity(
  state: CustomGuidesState,
  action: { id: string; materialNumber: string; uom: string; quantity: number },
): CustomGuidesState {
  const record = cloneCustomGuideRecord(
    state.recordsState.entities[action.id].record,
  );
  const material = getMaterialRecordFromCustomGuideRecord(
    record,
    action.materialNumber,
  );
  const existingParLine = material.parLines.find(
    (parLine) => parLine.uom === action.uom,
  );

  if (existingParLine) {
    existingParLine.inventoryQuantity = action.quantity;
  } else {
    material.parLines.push({
      parQuantity: 0,
      inventoryQuantity: action.quantity,
      uom: action.uom,
    });
  }

  return updateCustomGuideRecord(state, action.id, record);
}

function clearInventoryQuantities(
  state: CustomGuidesState,
  action: { id: string },
): CustomGuidesState {
  const record = cloneCustomGuideRecord(
    state.recordsState.entities[action.id].record,
  );
  record.categories.forEach((category) =>
    category.materials.forEach((material) =>
      material.parLines.forEach((parLine) => (parLine.inventoryQuantity = 0)),
    ),
  );

  return updateCustomGuideRecord(state, action.id, record);
}

function toggleParOrdering(
  state: CustomGuidesState,
  action: { id: string; parOrderingEnabled: boolean },
): CustomGuidesState {
  const change: Partial<CustomGuideRecord> = {
    parOrderingEnabled: action.parOrderingEnabled,
  };
  return updateCustomGuideRecord(state, action.id, change);
}

function getCategorizedCustomGuideSuccess(
  state: CustomGuidesState,
  action: {
    id: string;
    groupBy: GroupByType;
    categorizedMaterials: CategorizedMaterialNumbers[];
  },
): CustomGuidesState {
  let changes: Partial<CustomGuideRecordState>;
  switch (action.groupBy) {
    case GroupByType.Storage:
      changes = {
        storageAreaCategory: {
          hasLoaded: true,
          categories: action.categorizedMaterials,
        },
      };
      break;
    case GroupByType.Taxonomy:
      changes = {
        taxonomyCategory: {
          hasLoaded: true,
          categories: action.categorizedMaterials,
        },
      };
      break;
    case GroupByType.Custom:
    default:
      return state;
  }

  if (changes) {
    return updateCustomGuideRecordState(state, action.id, changes);
  } else {
    return state;
  }
}

function getCategorizedCustomGuideFailure(
  state: CustomGuidesState,
  action: { id: string; groupBy: GroupByType },
): CustomGuidesState {
  let changes: Partial<CustomGuideRecordState>;
  switch (action.groupBy) {
    case GroupByType.Storage:
      changes = {
        storageAreaCategory: {
          hasLoaded: true,
          categories: undefined,
        },
      };
      break;
    case GroupByType.Taxonomy:
      changes = {
        taxonomyCategory: {
          hasLoaded: true,
          categories: undefined,
        },
      };
      break;
    case GroupByType.Custom:
    default:
      return state;
  }

  if (changes) {
    return updateCustomGuideRecordState(state, action.id, changes);
  } else {
    return state;
  }
}

function clearCategorizedCustomGuide(
  state: CustomGuidesState,
  action: { id: string },
): CustomGuidesState {
  const changes: Partial<CustomGuideRecordState> = {
    storageAreaCategory: undefined,
    taxonomyCategory: undefined,
  };

  return updateCustomGuideRecordState(state, action.id, changes);
}

// Helper Functions

function updateCustomGuideRecord(
  state: CustomGuidesState,
  customGuideId: string,
  partialChange: Partial<CustomGuideRecord>,
): CustomGuidesState {
  const customGuideRecord = state.recordsState.entities[customGuideId].record;
  const changes: Partial<CustomGuideRecordState> = {
    record: {
      ...customGuideRecord,
      ...partialChange,
    },
  };

  return updateCustomGuideRecordState(state, customGuideId, changes);
}

function updateCustomGuideRecordState(
  state: CustomGuidesState,
  customGuideId: string,
  partialChange: Partial<CustomGuideRecordState>,
): CustomGuidesState {
  return {
    ...state,
    recordsState: customGuideAdapter.updateOne(
      {
        id: customGuideId,
        changes: partialChange,
      },
      state.recordsState,
    ),
  };
}

function getCategoryRecordsWithRemovedMaterials(
  categories: CustomGuideCategoryRecord[],
  materialNumbersToRemove: string[],
): CustomGuideCategoryRecord[] {
  const updatedCategories: CustomGuideCategoryRecord[] = [];

  categories.forEach((category) => {
    const materials: CustomGuideMaterialRecord[] = [];
    category.materials.forEach((material) => {
      if (!materialNumbersToRemove.includes(material.materialNumber)) {
        materials.push(material);
      }
    });
    updatedCategories.push({ name: category.name, materials: materials });
  });

  return updatedCategories;
}
