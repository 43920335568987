import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AvailableRouteDate,
  RouteDateContent,
} from '../../shared/models/delivery-date-picker';
import { DateAdapter } from '@angular/material/core';
import { LocalizationService } from 'src/app/shared/services/translation/localization.service';
import { Locale } from 'src/app/core/services/session/models/session-record';
import { CurrentSystem } from '../../../../core/services/session/models/session-record';
import { IKeyboardEvent } from 'src/app/shared/utilities/keyboard-event-utilities';
import moment, { Moment } from 'moment';

@Component({
  selector: 'naoo-delivery-date-picker-calendar',
  templateUrl: './delivery-date-picker-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryDatePickerCalendarComponent implements OnInit {
  @Input() routeDateContent: RouteDateContent;
  @Output() routeDateSelected = new EventEmitter<AvailableRouteDate>();
  @Output() shiftFocus = new EventEmitter<void>();

  selectedAvailableRouteDate?: AvailableRouteDate;

  constructor(
    private localizationService: LocalizationService,
    private dateAdapter: DateAdapter<Moment>,
  ) {}

  ngOnInit() {
    this.alignCalendarLocale();
  }

  onTabDown(event: IKeyboardEvent): boolean {
    if (event.target.tagName.toLowerCase() !== 'td') {
      return true;
    }

    this.shiftFocus.emit();
    return false;
  }

  get selectedDate(): Moment {
    return this.useArrivalDate
      ? moment.utc(this.calculatedAvailableRouteDate.customerArrivalDate)
      : moment.utc(this.calculatedAvailableRouteDate.routeDate);
  }

  get calculatedAvailableRouteDate(): AvailableRouteDate {
    return (
      this.selectedAvailableRouteDate || this.routeDateContent.defaultRouteDate
    );
  }

  get startAtDate(): Moment {
    return this.useArrivalDate
      ? moment.utc(this.routeDateContent.defaultRouteDate.customerArrivalDate)
      : moment.utc(this.routeDateContent.defaultRouteDate.routeDate);
  }

  get calendarMinDate(): Moment {
    if (this.useArrivalDate) {
      return moment.utc(
        this.routeDateContent.availableRouteDates[0].customerArrivalDate,
      );
    }
    return moment.utc(this.routeDateContent.availableRouteDates[0].routeDate);
  }

  get calendarMaxDate(): Moment {
    if (this.useArrivalDate) {
      return moment.utc(
        this.routeDateContent.availableRouteDates[
          this.routeDateContent.availableRouteDates.length - 1
        ].customerArrivalDate,
      );
    }
    return moment.utc(
      this.routeDateContent.availableRouteDates[
        this.routeDateContent.availableRouteDates.length - 1
      ].routeDate,
    );
  }

  selectableRouteDateFilter = (calendarMoment: Moment): boolean => {
    const routeDateTimes = this.routeDateContent.availableRouteDates.map(
      (availableRouteDate) => {
        if (this.useArrivalDate) {
          return availableRouteDate.customerArrivalDate?.getTime();
        }
        return availableRouteDate.routeDate?.getTime();
      },
    );
    return routeDateTimes.indexOf(calendarMoment.toDate().getTime()) !== -1;
  };

  onRouteDateSelected(selectedMoment: Moment) {
    const selectedAvailableRouteDate =
      this.routeDateContent.availableRouteDates.find((availableRouteDate) => {
        if (this.useArrivalDate) {
          return (
            availableRouteDate.customerArrivalDate.getTime() ===
            selectedMoment.toDate().getTime()
          );
        }
        return (
          availableRouteDate.routeDate.getTime() ===
          selectedMoment.toDate().getTime()
        );
      });
    this.selectedAvailableRouteDate = selectedAvailableRouteDate;
    this.routeDateSelected.emit(selectedAvailableRouteDate);
    this.shiftFocus.emit();
  }

  private alignCalendarLocale() {
    moment.locale(this.localizationService.currentLocale);
    if (this.localizationService.currentLocale === Locale.fr_CA) {
      this.dateAdapter.setLocale('fr');
    } else {
      this.dateAdapter.setLocale('en');
    }
  }

  private get useArrivalDate(): boolean {
    return CurrentSystem.isMygfsOrSap(this.routeDateContent.currentSystem);
  }
}
