import {
  MaterialCommodityInfo,
  MaterialCompareCheckbox,
  MaterialLine,
  MaterialListStyle,
  MaterialOrdering,
  MaterialRow,
  MaterialRowContext,
  MaterialRowFooter,
  MaterialRowInfo,
  MaterialRowSharedOptions,
  MaterialRowStatus,
} from './models/material-row';
import { createSelector } from '@ngrx/store';
import { selectInventoryAvailabilityRecordState } from 'src/app/core/store/inventory-availability/inventory-availability.selectors';
import {
  selectAllMaterialInfoRecordStates,
  selectMaterialInfoRecordState,
} from 'src/app/core/store/material-info/material-info.selectors';
import {
  selectCartMaterial,
  selectFocusedMaterial,
  selectIsCartLoaded,
  selectRouteDate,
} from 'src/app/core/store/cart/cart.selectors';
import { selectCustomerMaterialInfo } from 'src/app/core/store/customer-material/customer-material.selector';
import { selectWarning } from 'src/app/core/store/material-warning/material-warning.selectors';
import { selectCombinedPricingRecord } from 'src/app/core/store/material-price/material-price.selectors';
import { InventoryAvailabilityRecordState } from 'src/app/core/store/inventory-availability/inventory-availability.state';
import { MaterialInfoRecordState } from 'src/app/core/store/material-info/material-info.state';
import {
  CartMaterialState,
  FocusedMaterialState,
} from 'src/app/core/store/cart/cart.state';
import { MaterialWarning } from 'src/app/core/store/material-warning/material-warning';
import { MaterialFlag } from 'src/app/material-flag/material-flag';
import { selectMaterialFlags } from './material-flags.selectors';
import { MaterialAvailabilityRecordState } from '../material-availability/material-availability.state';
import {
  selectAllMaterialAvailabilityRecords,
  selectMaterialAvailabilityRecordLoadedState,
  selectMaterialAvailabilityRecordState,
} from '../material-availability/material-availability.selectors';
import { CustomerMaterialInfo } from '../../services/customer-material/model/customer-material-info.model';
import { selectLastOrderedRecordState } from '../last-ordered/last-ordered.selectors';
import { selectAreCutoffsLoaded } from '../material-cutoff/material-cutoff.selectors';
import {
  selectCanCompareMoreMaterials,
  selectIsMaterialSelectedForComparing,
} from '../material-comparison/material-comparison.selectors';
import { selectIsOffline } from '../offline-mode/offline-mode.selectors';
import { LastOrderedRecordState } from '../last-ordered/last-ordered.state';
import { selectCustomGuideRecord } from '../custom-guide/custom-guide.selectors';
import { CustomGuideMaterialRecord } from '../../services/custom-guide/model/custom-guide-record';
import { getMaterialRecordFromCustomGuideRecord } from '../custom-guide/custom-guide.util';
import {
  selectIsLoyaltyProgramEligible,
  selectLocale,
} from '../session/session.selectors';
import { Locale } from '../../services/session/models/session-record';
import {
  selectAllEntitlementMaterialDetails,
  selectEntitlementMaterialDetail,
  selectOverallocatedMaterialDetail,
} from '../entitlement/entitlement.selectors';
import { EntitlementMaterialDetail } from '../../services/entitlement/models/entitlement';
import { selectMaterialVendorInfoRecord } from '../material-additional-info/material-additional-info.selectors';
import { VendorInfoRecord } from '../../services/material-additional-info/models/material-additional-infos-record';
import { CombinedPricingRecord } from '../material-price/material-price.util';
import { OverallocatedMaterialDetail } from '../entitlement/entitlement.state';
import { selectIsRecommendedProduct } from '../search/search.selectors';
import { selectIsInOrderGuide } from '../order-guide/order-guide.selectors';
import { EntityState } from '@ngrx/entity/src/models';
import { Dictionary } from '@ngrx/entity';
import { isEntitlementMaterialDetailUnorderable } from '../entitlement/entitlement.util';
import { MaterialRowTransformer } from './transformer/material-row.transformer';
import { MaterialOrderingTransformer } from './transformer/material-ordering.transformer';
import { MaterialRowFooterTransformer } from './transformer/material-row-footer.transformer';
import { MaterialUnitInfo } from '../../../shared/models/material-info';
import { AuxiliaryAnalyticsData } from '../../services/ecommerce-analytics/models/google-events';

export interface MaterialData {
  customerMaterialInfo: CustomerMaterialInfo;
  availability: MaterialAvailabilityRecordState;
  inventory: InventoryAvailabilityRecordState;
  info: MaterialInfoRecordState;
  lastOrdered: LastOrderedRecordState;
  combinedPrice: CombinedPricingRecord;
  materialCommodityInfo: MaterialCommodityInfo;
}

export interface MaterialAuxiliaryData {
  focusedMaterial: FocusedMaterialState;
  routeDate: Date;
  areCutoffsLoaded: boolean;
  isOffline: boolean;
  locale: Locale;
  isCartLoaded: boolean;
  isLoyaltyProgramEligible: boolean;
  isRecommendedProduct?: boolean;
  isInOrderGuide?: boolean;
}

export interface CustomGuideData {
  id: string;
  isParEnabled: boolean;
  material?: CustomGuideMaterialRecord;
}

export interface MaterialRowSelectInput {
  analytics?: AuxiliaryAnalyticsData;
  context: MaterialRowContext;
  customGuideId?: string;
  materialLines?: MaterialLine[];
  materialListStyle: MaterialListStyle;
  materialNumber: string;
}

export interface MaterialOrderingSelectInput {
  context: MaterialRowContext;
  customGuideId?: string;
  materialLines?: MaterialLine[];
  materialNumber: string;
}

export interface MaterialRowFooterSelectInput {
  context: MaterialRowContext;
  materialNumber: string;
}

export interface MaterialRowOptionsSelectInput {
  analytics?: AuxiliaryAnalyticsData;
  context: MaterialRowContext;
  customGuideId?: string;
  materialListStyle: MaterialListStyle;
  materialNumber: string;
}

const selectMaterialData = (materialNumber: string) => {
  return createSelector(
    selectCustomerMaterialInfo(materialNumber),
    selectMaterialAvailabilityRecordState(materialNumber),
    selectInventoryAvailabilityRecordState(materialNumber),
    selectMaterialInfoRecordState(materialNumber),
    selectLastOrderedRecordState(materialNumber),
    selectCombinedPricingRecord(materialNumber),
    selectMaterialCommodityInfo(materialNumber),
    (
      customerMaterialInfo: CustomerMaterialInfo,
      availability: MaterialAvailabilityRecordState,
      inventory: InventoryAvailabilityRecordState,
      info: MaterialInfoRecordState,
      lastOrdered: LastOrderedRecordState,
      combinedPrice: CombinedPricingRecord,
      materialCommodityInfo: MaterialCommodityInfo,
    ): MaterialData => {
      return {
        customerMaterialInfo,
        availability,
        inventory,
        info,
        lastOrdered,
        combinedPrice,
        materialCommodityInfo,
      };
    },
  );
};

const selectComparisonData = (materialNumber: string) => {
  return createSelector(
    selectCanCompareMoreMaterials,
    selectIsMaterialSelectedForComparing(materialNumber),
    (canCompare: boolean, isComparing: boolean): MaterialCompareCheckbox => {
      return {
        canCompare,
        isComparing,
      };
    },
  );
};

const selectMaterialAuxiliaryData = (materialNumber: string) =>
  createSelector(
    selectFocusedMaterial,
    selectAreCutoffsLoaded,
    selectRouteDate,
    selectIsOffline,
    selectLocale,
    selectIsCartLoaded,
    selectIsLoyaltyProgramEligible,
    selectIsRecommendedProduct(materialNumber),
    selectIsInOrderGuide(materialNumber),
    (
      focusedMaterial: FocusedMaterialState,
      areCutoffsLoaded: boolean,
      routeDate: Date,
      isOffline: boolean,
      locale: Locale,
      isCartLoaded: boolean,
      isLoyaltyProgramEligible: boolean,
      isRecommendedProduct: boolean,
      isInOrderGuide: boolean,
      // eslint-disable-next-line max-params
    ): MaterialAuxiliaryData => {
      return {
        focusedMaterial,
        areCutoffsLoaded,
        routeDate,
        isOffline,
        locale,
        isCartLoaded,
        isLoyaltyProgramEligible,
        isRecommendedProduct,
        isInOrderGuide,
      };
    },
  );

const selectCustomGuideData = (customGuideId: string, materialNumber: string) =>
  createSelector(selectCustomGuideRecord(customGuideId), (record) => {
    if (!record) {
      return undefined;
    }

    const material = record.parOrderingEnabled
      ? getMaterialRecordFromCustomGuideRecord(record, materialNumber)
      : undefined;

    return {
      id: customGuideId,
      isParEnabled: record.parOrderingEnabled,
      material,
    };
  });

const selectMaterialCommodityInfo = (materialNumber: string) => {
  return createSelector(
    selectEntitlementMaterialDetail(materialNumber),
    selectOverallocatedMaterialDetail(materialNumber),
    selectMaterialVendorInfoRecord(materialNumber),
    selectMaterialInfoRecordState(materialNumber),
    (
      entitlementMaterialDetail: EntitlementMaterialDetail,
      overallocatedMaterialDetail: OverallocatedMaterialDetail,
      vendorInfoRecord: VendorInfoRecord,
      info: MaterialInfoRecordState | undefined,
    ): MaterialCommodityInfo | undefined =>
      MaterialRowTransformer.transformMaterialCommodityInfo(
        entitlementMaterialDetail,
        overallocatedMaterialDetail,
        vendorInfoRecord,
        info,
      ),
  );
};

const selectMaterialRowStatus = (materialNumber: string) =>
  createSelector(
    selectMaterialInfoRecordState(materialNumber),
    selectMaterialAvailabilityRecordLoadedState(materialNumber),
    selectEntitlementMaterialDetail(materialNumber),
    (
      info: MaterialInfoRecordState | undefined,
      availability: MaterialAvailabilityRecordState,
      entitlementMaterialDetail: EntitlementMaterialDetail,
    ): MaterialRowStatus =>
      MaterialRowTransformer.transformMaterialRowStatus(
        info,
        availability,
        isEntitlementMaterialDetailUnorderable(entitlementMaterialDetail),
      ),
  );

const selectMaterialRowContext = (
  context: MaterialRowContext,
  materialNumber: string,
) => {
  return createSelector(
    selectMaterialFlags(materialNumber, context),
    (flags: MaterialFlag[]): MaterialRowContext =>
      MaterialRowTransformer.transformContext({
        context,
        flags,
      }),
  );
};

const selectMaterialRowIsDeleted = (materialNumber: string) =>
  createSelector(
    selectCartMaterial(materialNumber),
    selectFocusedMaterial,
    (
      cartMaterial: CartMaterialState,
      focusedMaterial: FocusedMaterialState,
    ): boolean =>
      MaterialRowTransformer.transformIsDeleted({
        cartMaterial,
        focusedMaterial,
        materialNumber,
      }),
  );

const selectMaterialOrderingIsInventoryLoaded = (materialNumber: string) =>
  createSelector(
    selectAreCutoffsLoaded,
    selectInventoryAvailabilityRecordState(materialNumber),
    selectRouteDate,
    (
      areCutoffsLoaded: boolean,
      inventory: InventoryAvailabilityRecordState,
      routeDate: Date,
    ): boolean => !!inventory?.record && (areCutoffsLoaded || !routeDate),
  );

export const selectOkStatusMaterialRowMaterials = (
  materialNumbers: string[],
) => {
  return createSelector(
    selectAllMaterialInfoRecordStates,
    selectAllMaterialAvailabilityRecords,
    selectAllEntitlementMaterialDetails,
    (
      materialInfos: Dictionary<MaterialInfoRecordState>,
      availabilities: Dictionary<MaterialAvailabilityRecordState>,
      entitlementDetails: EntityState<EntitlementMaterialDetail> | undefined,
    ): string[] => {
      return (
        materialNumbers?.filter((materialNumber) => {
          const info = materialInfos[materialNumber];
          const availability = availabilities[materialNumber];
          const isCommodityUnorderable = isEntitlementMaterialDetailUnorderable(
            entitlementDetails?.entities[materialNumber],
          );

          return (
            MaterialRowStatus.Ok ===
            MaterialRowTransformer.transformMaterialRowStatus(
              info,
              availability,
              isCommodityUnorderable,
            )
          );
        }) || []
      );
    },
  );
};

export const selectMaterialRow = ({
  analytics,
  context,
  customGuideId,
  materialLines,
  materialListStyle,
  materialNumber,
}: MaterialRowSelectInput) =>
  createSelector(
    selectMaterialFlags(materialNumber, context),
    selectMaterialRowIsDeleted(materialNumber),
    selectMaterialOrdering({
      context,
      customGuideId,
      materialLines,
      materialNumber,
    }),
    selectMaterialRowFooter({ context, materialNumber }),
    selectMaterialRowInfo(materialNumber),
    selectMaterialRowOptions({
      analytics,
      context,
      customGuideId,
      materialListStyle,
      materialNumber,
    }),
    selectMaterialRowStatus(materialNumber),
    (
      flags: MaterialFlag[],
      isDeleted: boolean,
      materialOrdering: MaterialOrdering,
      materialRowFooter: MaterialRowFooter,
      materialRowInfo: MaterialRowInfo,
      materialRowOptions: MaterialRowSharedOptions,
      status: MaterialRowStatus,
    ): MaterialRow =>
      <MaterialRow>{
        flags,
        isDeleted,
        materialOrdering,
        materialRowFooter,
        materialRowInfo,
        materialRowOptions,
        status,
      },
  );

export const selectMaterialOrdering = ({
  context,
  customGuideId,
  materialLines,
  materialNumber,
}: MaterialOrderingSelectInput) =>
  createSelector(
    selectCartMaterial(materialNumber),
    selectCustomGuideData(customGuideId, materialNumber),
    selectMaterialOrderingIsInventoryLoaded(materialNumber),
    selectMaterialData(materialNumber),
    selectWarning(materialNumber),
    selectMaterialRowContext(context, materialNumber),
    selectMaterialRowStatus(materialNumber),
    (
      cartMaterial: CartMaterialState,
      customGuideData: CustomGuideData,
      isInventoryLoaded: boolean,
      materialData: MaterialData,
      materialWarning: MaterialWarning,
      newContext: MaterialRowContext,
      status: MaterialRowStatus,
    ): MaterialOrdering | undefined => {
      const { availability, combinedPrice, info } = materialData;
      return MaterialOrderingTransformer.transform({
        availabilityRecord: availability?.record,
        cartMaterial,
        combinedPrice,
        context: newContext,
        customGuideMaterial: customGuideData?.material,
        infoRecord: info?.record,
        isInventoryLoaded,
        materialLines,
        materialWarning,
        status,
      });
    },
  );

export const selectMaterialRowFooter = ({
  context,
  materialNumber,
}: MaterialRowFooterSelectInput) =>
  createSelector(
    selectMaterialAvailabilityRecordState(materialNumber),
    selectComparisonData(materialNumber),
    selectMaterialRowContext(context, materialNumber),
    selectMaterialInfoRecordState(materialNumber),
    selectLastOrderedRecordState(materialNumber),
    (
      availability: MaterialAvailabilityRecordState | undefined,
      compareCheckbox: MaterialCompareCheckbox,
      newContext: MaterialRowContext,
      info: MaterialInfoRecordState | undefined,
      lastOrdered: LastOrderedRecordState | undefined,
    ): MaterialRowFooter | undefined =>
      MaterialRowFooterTransformer.transform({
        availabilityRecord: availability?.record,
        compareCheckbox,
        context: newContext,
        infoRecord: info?.record,
        lastOrderedRecord: lastOrdered?.record,
      }),
  );

export const selectMaterialRowInfo = (materialNumber: string) =>
  createSelector(
    selectMaterialInfoRecordState(materialNumber),
    selectMaterialRowStatus(materialNumber),
    (
      info: MaterialInfoRecordState | undefined,
      status: MaterialRowStatus,
    ): MaterialRowInfo | undefined => {
      const infoRecord = info?.record;
      if (
        !infoRecord ||
        [MaterialRowStatus.Loading, MaterialRowStatus.Unavailable].includes(
          status,
        )
      ) {
        return undefined;
      }
      const { brand, description, units } = infoRecord;
      return {
        description: {
          en: description?.en,
          fr: description?.fr,
        },
        brand: {
          en: brand?.en,
          fr: brand?.fr,
        },
        innerPackSize: infoRecord.innerPackSize,
        units: units.map((unit) => {
          const {
            uom,
            displayCode,
            parentUom,
            qtyInParent,
            primaryGtin,
            alternateGtins,
          } = unit;
          return <MaterialUnitInfo>{
            uom,
            displayCode,
            parentUom,
            qtyInParent,
            primaryGtin,
            alternateGtins,
          };
        }),
      };
    },
  );

export const selectMaterialRowOptions = ({
  analytics,
  context,
  customGuideId,
  materialListStyle,
  materialNumber,
}: MaterialRowOptionsSelectInput) =>
  createSelector(
    selectCustomGuideData(customGuideId, materialNumber),
    selectCustomerMaterialInfo(materialNumber),
    selectMaterialAuxiliaryData(materialNumber),
    selectMaterialCommodityInfo(materialNumber),
    selectMaterialRowContext(context, materialNumber),
    (
      customGuideData: CustomGuideData,
      customerMaterialInfo: CustomerMaterialInfo,
      materialAuxiliaryData: MaterialAuxiliaryData,
      materialCommodityInfo: MaterialCommodityInfo,
      newContext: MaterialRowContext,
    ): MaterialRowSharedOptions => {
      const {
        isCartLoaded,
        isInOrderGuide,
        isLoyaltyProgramEligible,
        isOffline,
        isRecommendedProduct,
        locale,
      } = materialAuxiliaryData;

      let customGuideId = undefined;
      let isParEnabled = false;
      if (customGuideData) {
        customGuideId = customGuideData.id;
        isParEnabled = customGuideData.isParEnabled;
      }
      return {
        context: newContext,
        customGuideId,
        customerMaterialNumber: customerMaterialInfo?.customerMaterialNumber,
        isCartLoaded,
        isLoyaltyProgramEligible,
        isOffline,
        isOrderGuideItem: isInOrderGuide,
        isParEnabled,
        isSponsoredMaterial: isRecommendedProduct,
        isSponsoredMaterialView: [
          MaterialListStyle.Grid,
          MaterialListStyle.List,
        ].includes(materialListStyle),
        locale,
        materialCommodityInfo,
        materialListStyle,
        materialNumber,
        analytics,
      };
    },
  );
