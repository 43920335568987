export enum ImportOrderRowErrors {
  TooManyCases = 'TooManyCases',
  TooManyUnits = 'TooManyUnits',
  EmptyId = 'EmptyId',
  CasesNotNumeric = 'CasesNotNumeric',
  UnitsNotNumeric = 'UnitsNotNumeric',
  NegativeCase = 'NegativeCase',
  NegativeUnit = 'NegativeUnit',
  FractionalCase = 'FractionalCase',
  FractionalUnit = 'FractionalUnit',
  TooManyMergedCases = 'TooManyMergedCases',
  TooManyMergedUnits = 'TooManyMergedUnits',
  NotAvailable = 'NotAvailable',
  NotUnitOrderable = 'NotUnitOrderable',
  NoLongerAvailable = 'NoLongerAvailable',
  NotNumeric = 'NotNumeric',
  WrongBreakCase = 'WrongBreakCase',
}

export const importOrderImportableErrors: ImportOrderRowErrors[] = [
  ImportOrderRowErrors.NoLongerAvailable,
  ImportOrderRowErrors.NotAvailable,
  ImportOrderRowErrors.NotUnitOrderable,
];

export class ImportOrderRow {
  rowNumber: number;
  rawId: string;
  customerMaterialNumber?: string;
  offeringId: string;
  caseQuantity: string;
  eachQuantity: string;
  breakCase?: string;
  error?: ImportOrderRowErrors;

  constructor(
    rowNumber: number,
    rawId: string,
    offeringId: string,
    caseQuantity: string,
    eachQuantity: string,
    breakCase?: string,
    customerMaterialNumber?: string,
  ) {
    this.rowNumber = rowNumber;
    this.rawId = rawId;
    this.customerMaterialNumber = customerMaterialNumber;
    this.offeringId = offeringId;
    this.caseQuantity = caseQuantity;
    this.eachQuantity = eachQuantity;
    this.breakCase = breakCase;
  }
}
