import { CartEntityState } from '../../../core/store/cart/cart.state';
import { CartMaterialUpdateRequest } from '../../../core/services/cart/models/cart-record';
import { MaterialRecord } from '../../../core/services/material-info/models/materials-info-record';
import { Language } from '../../../core/services/session/models/session-record';
import { CombinedPricingRecord } from '../../../core/store/material-price/material-price.util';

export enum FacebookEventType {
  PageView = 'PageView',
  ViewContent = 'ViewContent',
  AddToCart = 'AddToCart',
  Checkout = 'InitiateCheckout',
  Purchase = 'Purchase',
}

export interface FacebookContent {
  id: string;
  quantity: number;
}

export interface FacebookViewContentEvent {
  content_name: string;
}

export interface FacebookAddToCartEvent {
  contents: FacebookContent[];
  content_type: string;
  content_name: string;
  currency: string;
  value: number;
}

export interface FacebookCheckoutPurchaseEvent {
  contents: FacebookContent[];
  content_type: string;
  num_items: number;
  currency: string;
  value: number;
}

export type FacebookEvent =
  | FacebookAddToCartEvent
  | FacebookCheckoutPurchaseEvent
  | FacebookViewContentEvent;

export function buildFacebookViewContentEvent(
  url: string,
): FacebookViewContentEvent {
  return {
    content_name: url,
  };
}

export function buildFacebookAddToCartEvents(
  changedMaterials: CartMaterialUpdateRequest[],
  currentCart: CartEntityState,
  previousCart: CartEntityState,
  materialInfos: Map<string, MaterialRecord>,
  combinedPrices: Map<string, CombinedPricingRecord>,
  currentLanguage: Language,
  currency: string,
): FacebookAddToCartEvent[] {
  const addToCartEvents: FacebookAddToCartEvent[] = [];

  changedMaterials.forEach((changedMaterial) => {
    const previousMaterial =
      previousCart.materials.entities[changedMaterial.materialNumber];
    const materialInfo = materialInfos.get(changedMaterial.materialNumber);
    const materialPrice = combinedPrices.get(changedMaterial.materialNumber);

    changedMaterial.lines.forEach((changedLine) => {
      let quantity: number;
      if (
        !!previousMaterial &&
        !!previousMaterial.lines.entities[changedLine.uom]
      ) {
        quantity =
          changedLine.quantity -
          previousMaterial.lines.entities[changedLine.uom].quantity;
      } else {
        quantity = changedLine.quantity;
      }

      if (quantity > 0) {
        // Only track added quantities, not removed
        addToCartEvents.push({
          contents: [
            {
              id: changedMaterial.materialNumber,
              quantity: quantity,
            },
          ],
          content_type: 'product',
          content_name: getTranslatedDescription(materialInfo, currentLanguage),
          currency: currency,
          value: getMaterialPrice(materialPrice, changedLine.uom),
        });
      }
    });
  });

  return addToCartEvents;
}

export function buildFacebookCheckoutPurchaseEvent(
  cart: CartEntityState,
  totalCost: number,
  currency: string,
): FacebookCheckoutPurchaseEvent {
  const contents: FacebookContent[] = [];
  let materialCount = 0;

  Object.values(cart.materials.entities)
    .filter((material) => !material.isDeleted)
    .forEach((material) => {
      materialCount++;

      Object.values(material.lines.entities)
        .filter((line) => line.quantity > 0)
        .forEach((line) =>
          contents.push({
            id: material.materialNumber,
            quantity: line.quantity,
          }),
        );
    });

  return {
    contents: contents,
    content_type: 'product',
    num_items: materialCount,
    currency: currency,
    value: totalCost,
  };
}

function getTranslatedDescription(
  materialInfo: MaterialRecord,
  currentLanguage: Language,
): string | undefined {
  if (!materialInfo) {
    return undefined;
  }

  return currentLanguage === Language.fr && !!materialInfo.description.fr
    ? materialInfo.description.fr
    : materialInfo.description?.en;
}

function getMaterialPrice(
  materialPrice: CombinedPricingRecord,
  uom: string,
): number {
  if (!materialPrice) {
    return 0;
  }

  const uomPrice = materialPrice.unitPrices?.find(
    (unitPrice) => unitPrice.salesUom === uom,
  );
  return uomPrice ? uomPrice.price : 0;
}
