import { Injectable } from '@angular/core';
import { StoreService } from '../store/store.service';
import {
  CheckRedemptionRequest,
  CustomerPointsRecord,
} from '../store/model/store-record';
import { first, map } from 'rxjs/operators';
import { NaooConstants } from '../../../shared/NaooConstants';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import { Router } from '@angular/router';
import { EcommerceAnalyticsFacade } from '../../store/ecommerce-analytics/ecommerce-analytics.facade';
import { MessageData } from '../../../shared/modals/generic-modal/generic-modal.component';
import { Observable } from 'rxjs';
import { SessionFacade } from '../../store/session/session.facade';
import { SiteAddressRecord } from '../session/models/session-record';
import { toTitleCase } from '../../../shared/utilities/string-utilities';

export interface GoPointsViewModel {
  checkEligible: boolean;
  checkPointValue?: number;
  loyaltyPointsBalance?: number;
  hasPoints: boolean;
  tierIcon: string;
  tierText: string;
  maxNumberOfChecks: number;
}

@Injectable({
  providedIn: 'root',
})
export class GoPointsService {
  private defaultStoreAddress: SiteAddressRecord;

  constructor(
    private storeService: StoreService,
    private sessionFacade: SessionFacade,
    private dialogService: DefaultDialogService,
    private router: Router,
    private ecommerceAnalyticsFacade: EcommerceAnalyticsFacade,
  ) {
    this.sessionFacade.getPrimarySite().subscribe((store) => {
      this.defaultStoreAddress = store?.address;
    });
  }

  checkRedemption(numberOfChecks: number): void {
    const imageData = {
      imageLocation: './assets/images/cart-with-checkmark.svg',
      altText: '',
    };

    const request: CheckRedemptionRequest = {
      numberOfChecks,
    };

    this.ecommerceAnalyticsFacade.trackNumberOfLoyaltyChecksSubmitted(
      numberOfChecks,
    );

    const messageData = this.getMessageData();

    this.storeService
      .checkRedemption(request)
      .pipe(first())
      .subscribe({
        next: () => {
          this.dialogService.prompt(
            'Go Points check redeemed',
            'GO_POINTS.LABELS.SUCCESS',
            true,
            true,
            imageData,
            messageData,
            'GO_POINTS.LABELS.CONTINUE_SHOPPING',
            () => {
              this.router.navigate([NaooConstants.HOME_PAGE_PATH]);
            },
            'GO_POINTS.LABELS.POINTS_REDEEMED',
            'naoo-go-points',
          );
        },
      });
  }

  retrieveGoPointsViewModel(): Observable<GoPointsViewModel> {
    return this.storeService
      .retrieveCustomerPoints()
      .pipe(
        map((customerPointsRecord) => this.toViewModel(customerPointsRecord)),
      );
  }

  private toViewModel(
    customerPointsRecord: CustomerPointsRecord,
  ): GoPointsViewModel {
    const { checkEligible, checkPointValue, loyaltyPointsBalance, tierName } =
      customerPointsRecord;

    const maxNumberOfChecks =
      checkPointValue > 0 && loyaltyPointsBalance
        ? Math.floor(loyaltyPointsBalance / checkPointValue)
        : 0;

    return {
      checkEligible,
      checkPointValue,
      loyaltyPointsBalance,
      hasPoints: loyaltyPointsBalance > 0,
      tierIcon: `loyalty_tier_${tierName?.toLowerCase()}`,
      tierText: `GO_POINTS.TIERS.${tierName?.toUpperCase()}`,
      maxNumberOfChecks,
    };
  }

  private getMessageData(): MessageData | string {
    if (
      this.defaultStoreAddress?.address1 &&
      this.defaultStoreAddress?.city &&
      this.defaultStoreAddress?.stateProvinceCode
    ) {
      return {
        messageKey: 'GO_POINTS.LABELS.CHECK_REQUEST_RECEIVED',
        parameters: {
          value: `${toTitleCase(
            this.defaultStoreAddress.address1,
          )}, ${toTitleCase(this.defaultStoreAddress.city)}, ${
            this.defaultStoreAddress.stateProvinceCode
          }`,
        },
      } as MessageData;
    } else {
      return 'GO_POINTS.LABELS.CHECK_REQUEST_RECEIVED_DEFAULT';
    }
  }
}
