<div
  class="parent-container"
  [ngClass]="{
    'red-border': shouldShowRedBorder,
    'green-border': shouldShowGreenBorder,
    'gray-border': shouldShowGrayBorder,
    'no-side-borders': isInsideContainer,
    'last-row': shouldHideBottomBorder,
    'hide-shadow': shouldHideShadow,
    mobile: isMobile,
    'extended-height': hasMaterialFooter,
  }"
>
  <div class="material-row-savings" *ngIf="shouldShowGreenBorder">
    {{ 'CART_COUPON.SAVED' | translate }}
    {{ getSavingsTotal | naooprice }}
  </div>

  <div
    *ngIf="isOK"
    class="content material-content consistent-height"
    [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
    @fadeInOnly
  >
    <div class="image-wrapper" *ngIf="hasMaterialFooter && !isMobile">
      <naoo-material-image
        [materialRowOptions]="materialRow.materialRowOptions"
        [isUnorderable]="false"
        [isMobile]="isMobile"
        [ngClass]="{ 'right-border': hasMaterialFooter }"
      ></naoo-material-image>
      <naoo-material-store-stored-button
        [fulfillmentType]="fulfillmentType"
        [materialAvailability]="materialAvailability"
        [materialRowOptions]="materialRow.materialRowOptions"
      >
      </naoo-material-store-stored-button>
    </div>
    <div
      class="details-container"
      [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
    >
      <div class="row-details" [ngClass]="{ mobile: isMobile }">
        <div
          class="info-content"
          [ngClass]="{
            mobile: isMobile,
            'par-enabled': isParEnabled,
            'loyalty-enabled':
              materialRow.materialRowOptions.isLoyaltyProgramEligible,
          }"
        >
          <div
            class="info-content-wrapper"
            [ngClass]="{ 'display-max-allocation': displayMaxAllocation }"
          >
            <div class="image-wrapper" *ngIf="!hasMaterialFooter || isMobile">
              <naoo-material-image
                [materialRowOptions]="materialRow.materialRowOptions"
                [isUnorderable]="false"
                [isMobile]="isMobile"
              ></naoo-material-image>
              <naoo-material-store-stored-button
                [fulfillmentType]="fulfillmentType"
                [materialAvailability]="materialAvailability"
                [materialRowOptions]="materialRow.materialRowOptions"
              >
              </naoo-material-store-stored-button>
            </div>
            <div
              class="details-content"
              [ngClass]="{
                mobile: isMobile,
                'full-width': isInsideContainer,
                'extended-image': hasMaterialFooter,
              }"
            >
              <naoo-material-info
                [materialRowOptions]="materialRow.materialRowOptions"
                [materialInfo]="materialRow.materialRowInfo"
                [isMobile]="isMobile"
              ></naoo-material-info>
              <div
                class="mobile-triple-dot"
                *ngIf="!isInsideContainer && isMobile"
              >
                <naoo-material-options-menu
                  [materialRowOptions]="materialRow.materialRowOptions"
                  [isMobile]="isMobile"
                >
                </naoo-material-options-menu>
              </div>
              <ng-container *ngIf="!isMobile">
                <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
              </ng-container>
            </div>
          </div>
          <span *ngIf="displayMaxAllocation" class="commodity-available">
            <button (click)="viewCommodityDetailsClicked.emit()">
              {{
                materialRow.materialRowOptions.materialCommodityInfo
                  .maxAllocationDisplayKey
                  | translate
                    : {
                        count:
                          materialRow.materialRowOptions.materialCommodityInfo
                            .maxAllocationDisplay,
                      }
              }}
            </button>
          </span>
        </div>

        <div
          *ngIf="isMobile"
          class="mobile-content mobile"
          [ngClass]="{ empty: hasNoFlags }"
        >
          <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
        </div>

        <div
          class="ordering-content"
          [ngClass]="{
            mobile: isMobile,
            'par-enabled': isParEnabled,
            'loyalty-enabled':
              materialRow.materialRowOptions.isLoyaltyProgramEligible,
          }"
        >
          <ng-container>
            <naoo-material-ordering
              *ngIf="!!materialRow.materialOrdering"
              [materialOrdering]="materialRow.materialOrdering"
              [materialRowOptions]="materialRow.materialRowOptions"
              [isMobile]="isMobile"
              [ngClass]="{ mobile: isMobile }"
              [displayGoPointsLogo]="displayGoPointsLogo"
              (quantityInputChanged)="quantityInputChanged.emit($event)"
              (viewCommodityDetailsClicked)="viewCommodityDetailsClicked.emit()"
            ></naoo-material-ordering>
          </ng-container>

          <div class="triple-dot" *ngIf="!isInsideContainer && !isMobile">
            <naoo-material-options-menu
              [materialRowOptions]="materialRow.materialRowOptions"
              [isMobile]="isMobile"
            >
            </naoo-material-options-menu>
          </div>
          <div
            class="triple-dot"
            *ngIf="
              MaterialRowContext.PdpLinkItem ===
                this.materialRow?.materialRowOptions?.context && !isMobile
            "
          ></div>
        </div>
      </div>

      <div
        class="footer-content"
        *ngIf="hasMaterialFooter"
        [ngClass]="{ mobile: isMobile }"
      >
        <naoo-material-row-footer
          [materialRowFooter]="materialRow.materialRowFooter"
          [materialRowOptions]="materialRow.materialRowOptions"
          [isMobile]="isMobile"
          *ngIf="!!materialRow.materialRowFooter"
          (viewCommodityDetailsClicked)="viewCommodityDetailsClicked.emit()"
        ></naoo-material-row-footer>
      </div>
    </div>
  </div>

  <div
    *ngIf="isUnorderable"
    class="content unorderable-content consistent-height"
    [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
    @fadeInOnly
  >
    <naoo-material-image
      [materialRowOptions]="materialRow.materialRowOptions"
      [isUnorderable]="true"
      [isMobile]="isMobile"
      *ngIf="hasMaterialFooter && !isMobile"
      [ngClass]="{ 'right-border': hasMaterialFooter && !isMobile }"
    ></naoo-material-image>
    <div
      class="details-container"
      [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
    >
      <div class="row-details" [ngClass]="{ mobile: isMobile }">
        <div
          class="info-content"
          [ngClass]="{
            mobile: isMobile,
            'par-enabled': isParEnabled,
            'loyalty-enabled':
              materialRow.materialRowOptions.isLoyaltyProgramEligible,
          }"
        >
          <naoo-material-image
            [materialRowOptions]="materialRow.materialRowOptions"
            [isUnorderable]="true"
            [isMobile]="isMobile"
            *ngIf="!hasMaterialFooter || isMobile"
            [ngClass]="{ 'right-border': hasMaterialFooter && !isMobile }"
          ></naoo-material-image>
          <div
            class="details-content"
            [ngClass]="{
              mobile: isMobile,
              'full-width': isInsideContainer,
              'extended-image': hasMaterialFooter,
            }"
          >
            <naoo-material-info
              [materialRowOptions]="materialRow.materialRowOptions"
              [materialInfo]="materialRow.materialRowInfo"
              [isMobile]="isMobile"
            ></naoo-material-info>
            <div
              class="mobile-triple-dot"
              *ngIf="!isInsideContainer && isMobile"
            >
              <naoo-material-options-menu
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
              >
              </naoo-material-options-menu>
            </div>
            <ng-container *ngIf="!isMobile">
              <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
            </ng-container>
          </div>
        </div>
        <div
          *ngIf="isMobile"
          class="mobile-content mobile"
          [ngClass]="{ empty: hasNoFlags }"
        >
          <ng-container *ngTemplateOutlet="materialFlag"></ng-container>
        </div>
        <div
          class="ordering-content-unorderable-item"
          [ngClass]="{
            mobile: isMobile,
            'par-enabled': isParEnabled,
            'loyalty-enabled':
              materialRow.materialRowOptions.isLoyaltyProgramEligible,
          }"
        >
          <ul class="unorderable-list-content" [ngClass]="{ mobile: isMobile }">
            <li class="unorderable-content" [ngClass]="{ mobile: isMobile }">
              <naoo-material-unorderable
                [materialRowOptions]="materialRow.materialRowOptions"
                [isMobile]="isMobile"
                [materialAvailability]="materialAvailability"
              >
              </naoo-material-unorderable>
            </li>
            <li class="similar-items">
              <naoo-material-similar-items
                [ngClass]="{ mobile: isMobile }"
                *ngIf="!isSubstitutesContext"
                [isMobile]="isMobile"
                [materialWithSubstitutes]="materialRow"
              >
              </naoo-material-similar-items>
            </li>
          </ul>
        </div>
        <div
          class="triple-dot"
          *ngIf="!isInsideContainer && !isMobile && !isErrorProcessing"
        >
          <naoo-material-options-menu
            [materialRowOptions]="materialRow.materialRowOptions"
            [isMobile]="isMobile"
          >
          </naoo-material-options-menu>
        </div>
      </div>
      <div
        class="footer-content"
        *ngIf="hasMaterialFooter"
        [ngClass]="{ mobile: isMobile }"
      >
        <naoo-material-row-footer
          [materialRowFooter]="materialRow.materialRowFooter"
          [materialRowOptions]="materialRow.materialRowOptions"
          *ngIf="!!materialRow.materialRowFooter"
          (viewCommodityDetailsClicked)="viewCommodityDetailsClicked.emit()"
        ></naoo-material-row-footer>
      </div>
    </div>
  </div>

  <div
    class="content consistent-height unavailable-content"
    *ngIf="isUnavailable"
    [ngClass]="{ mobile: isMobile }"
    @fadeInOnly
  >
    <naoo-material-unavailable
      [materialRowOptions]="materialRow.materialRowOptions"
      [isMobile]="isMobile"
      [isDeleteHidden]="shouldHideTrashCan"
    >
    </naoo-material-unavailable>
  </div>

  <div
    class="content deleted-content consistent-height"
    [ngClass]="{ mobile: isMobile }"
    *ngIf="isDeleted"
    @fadeInOnly
  >
    <naoo-material-deleted
      [materialInfo]="materialRow.materialRowInfo"
      [status]="materialRow.status"
      [materialRowOptions]="materialRow.materialRowOptions"
      [isMobile]="isMobile"
    >
    </naoo-material-deleted>
  </div>

  <div
    *ngIf="isLoading"
    class="content loading-content consistent-height"
    [ngClass]="{ mobile: isMobile, 'extended-height': hasMaterialFooter }"
    @fadeInAndOut
  >
    <naoo-material-skeleton
      [isMobile]="isMobile"
      [materialListStyle]="materialRow.materialRowOptions.materialListStyle"
    >
    </naoo-material-skeleton>
  </div>
</div>

<ng-template #materialFlag>
  <div class="flags" [ngClass]="{ mobile: isMobile }">
    <naoo-material-flag-container
      [materialRow]="materialRow"
      [maximumNumberOfFlags]="6"
    ></naoo-material-flag-container>
  </div>
</ng-template>
