import { Injectable } from '@angular/core';
import { LocalizationService } from '../translation/localization.service';
import { NaooDatePipe } from '../../pipes/naoo-date.pipe';
import { NaooConstants } from '../../NaooConstants';
import { Localized } from '../../models/localized';
import {
  Language,
  Locale,
} from '../../../core/services/session/models/session-record';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { ExpressDeliveryWindow } from '../../../core/services/express-schedules/models/express-schedule-record';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private naooDatePipe: NaooDatePipe;

  private readonly storeDateFormat = 'shortWeekdayMonthDay';

  constructor(private localizationService: LocalizationService) {
    this.naooDatePipe = new NaooDatePipe(this.localizationService);
  }

  getFormattedDate(value: any, format: string): string {
    return this.naooDatePipe.transform(value, format);
  }

  getLocalDateTime(
    dateTime: string | undefined,
    format: string,
    userTimeZone: string,
    showTz: boolean = true,
  ): string | undefined {
    const dateTimeMoment = this.setDateTimeMoment(dateTime, userTimeZone);
    if (!dateTimeMoment) {
      return undefined;
    }
    format = this.updateDateFormatForMay(dateTimeMoment, format);

    return this.getStringForLanguage(dateTime, format, dateTimeMoment, showTz);
  }

  getLocalizedDateString(
    dateTime: string | undefined,
    format: string,
    userTimeZone: string,
    showTz: boolean = true,
    trailingString?: string,
  ): Localized<string> | undefined {
    const dateTimeMoment = this.setDateTimeMoment(dateTime, userTimeZone);
    if (!dateTimeMoment) {
      return undefined;
    }
    format = this.updateDateFormatForMay(dateTimeMoment, format);

    return {
      en: this.getStringForLanguage(
        dateTime,
        format,
        dateTimeMoment,
        showTz,
        trailingString,
        Language.en,
        Locale.en_US,
      ),
      fr: this.getStringForLanguage(
        dateTime,
        format,
        dateTimeMoment,
        showTz,
        trailingString,
        Language.fr,
        Locale.fr_CA,
      ),
    };
  }

  private setDateTimeMoment(
    dateTime: string,
    userTimeZone: string,
  ): Moment | undefined {
    if (!dateTime) {
      return undefined;
    }
    if (!this.hasTimeZoneInDate(dateTime)) {
      return moment(dateTime);
    } else {
      return moment(dateTime).tz(userTimeZone);
    }
  }

  private hasTimeZoneInDate(dateTime: string): boolean {
    if (dateTime.length < 11) {
      return false;
    }
    const right = dateTime.substring(10);
    return right.includes('Z') || right.includes('+') || right.includes('-');
  }

  private updateDateFormatForMay(
    dateTimeMoment: moment.Moment,
    format: string,
  ): string {
    if (dateTimeMoment.month() === 4) {
      if (format === 'weekdayMonthDay') {
        format = 'weekdayMonthDay_may';
      } else if (format === 'weekdayMonthDayTime') {
        format = 'weekdayMonthDayTime_may';
      }
    }
    return format;
  }

  private getStringForLanguage(
    dateTime: string,
    format: string,
    dateTimeMoment: moment.Moment,
    showTz: boolean,
    trailingString: string = '',
    language?: Language,
    locale?: Locale,
  ): string {
    trailingString = !trailingString ? '' : trailingString;

    const translatedTimeZone = this.localizationService.instant(
      `TIMEZONES.${dateTimeMoment.zoneAbbr()}`,
      undefined,
      language,
    );

    const formattedString = this.naooDatePipe.transform(
      dateTime,
      format,
      dateTimeMoment.zoneAbbr(),
      locale,
    );
    return showTz
      ? `${formattedString} ${translatedTimeZone}${trailingString}`
      : `${formattedString}${trailingString}`;
  }

  getTimeRange(
    startTime: string | undefined,
    untilTime: string | undefined,
    userTimeZone: string,
  ): string | undefined {
    if (!startTime && !untilTime) {
      return undefined;
    }
    const isRange = startTime && untilTime;
    const start = startTime
      ? this.getLocalDateTime(startTime, 'time', userTimeZone, !isRange)
      : undefined;
    const until = untilTime
      ? this.getLocalDateTime(untilTime, 'time', userTimeZone)
      : undefined;

    if (isRange) {
      return `${start}${NaooConstants.NDASH}${until}`;
    }
    return start ? start : until;
  }

  getLocalizedTimeRange(
    startTime: string | undefined,
    untilTime: string | undefined,
    userTimeZone: string,
  ): Localized<string> | undefined {
    if (!startTime && !untilTime) {
      return undefined;
    }
    const isRange = startTime && untilTime;
    const start = startTime
      ? this.getLocalizedDateString(
          startTime,
          'time',
          userTimeZone,
          !isRange,
          '',
        )
      : undefined;
    const until = untilTime
      ? this.getLocalizedDateString(untilTime, 'time', userTimeZone, true, '')
      : undefined;

    if (isRange) {
      return {
        en: `${start.en}${NaooConstants.NDASH}${until.en}`,
        fr: `${start.fr}${NaooConstants.NDASH}${until.fr}`,
      };
    }
    return start ? start : until;
  }

  getDateTimeFromDateAndTime(
    date: string,
    time: string,
    userTimeZone: string,
  ): string | null {
    if (!date) {
      return null;
    }
    let dateTimeString = '';
    dateTimeString += date ?? '';
    dateTimeString += time ? ` ${time}` : '';
    if (dateTimeString) {
      const dateTimeMoment = moment.tz(dateTimeString, userTimeZone);
      return dateTimeMoment.isValid() ? dateTimeMoment.format() : null;
    }
    return null;
  }

  getTimeFromDate(
    date: string,
    time: string,
    userTimeZone: string,
  ): string | null {
    if (!date) {
      return null;
    }
    const dateTime = this.getDateTimeFromDateAndTime(date, time, userTimeZone);
    return this.getLocalDateTime(dateTime, 'time', userTimeZone, true) ?? null;
  }

  getStoreDate(
    customerTimeZone: string,
    expressDate?: ExpressDeliveryWindow,
    pickupDate?: Moment,
  ): string | undefined {
    if (pickupDate) {
      return this.naooDatePipe.transform(
        pickupDate.format(),
        this.storeDateFormat,
      );
    }
    if (expressDate) {
      const date = this.naooDatePipe.transform(
        expressDate.deliveryWindowStart,
        this.storeDateFormat,
      );
      const time = this.getTimeRange(
        expressDate.deliveryWindowStart,
        expressDate.deliveryWindowEnd,
        customerTimeZone,
      );
      return `${date} ${time}`;
    }
    return undefined;
  }
}
