import {
  NutritionBlockLine,
  NutritionLine,
} from '../nutrition/models/nutrition';

export interface NutrientMetadata {
  prefix: string;
  level: number;
  shouldReferenceDailyIntake: boolean;
}

function buildMetadata(
  prefix: string,
  level = 0,
  shouldReferenceDailyIntake = true,
): NutrientMetadata {
  return {
    prefix: prefix,
    level: level,
    shouldReferenceDailyIntake: shouldReferenceDailyIntake,
  };
}

/**
 * @deprecated use nutrition transformer service instead
 */
export class MygfsNutritionConstants {
  public static readonly nutrients: NutrientMetadata[] = [
    buildMetadata('fat'),
    buildMetadata('saturatedFat', 1),
    buildMetadata('transFattyAcid', 1, false),
    buildMetadata('polyUnsaturatedFat', 1, false),
    buildMetadata('monoUnsaturatedFat', 1, false),
    buildMetadata('cholesterol'),
    buildMetadata('sodium'),
    buildMetadata('carbohydrate'),
    buildMetadata('fiber', 1),
    buildMetadata('sugars', 1, false),
    buildMetadata('addedSugar', 2),
    buildMetadata('protein'),
  ];

  public static readonly focusedNutrients: NutrientMetadata[] = [
    buildMetadata('vitaminDMcg'),
    buildMetadata('calcium'),
    buildMetadata('iron'),
    buildMetadata('potassium'),
  ];

  public static readonly schoolEquivalents: NutrientMetadata[] = [
    buildMetadata('schoolPortion'),
    buildMetadata('sleSchoolMeat'),
    buildMetadata('sleSchoolFruit'),
    buildMetadata('sleSchoolBread'),
    buildMetadata('schoolRedOrange'),
    buildMetadata('schoolDarkGreen'),
    buildMetadata('schoolStarchy'),
    buildMetadata('schoolBeans'),
    buildMetadata('schoolOther'),
    buildMetadata('schoolNotes'),
  ];

  public static readonly fatSolubleVitamins: NutrientMetadata[] = [
    buildMetadata('vitaminEMg'),
    buildMetadata('vitaminK'),
    buildMetadata('vitaminAIU'),
  ];

  public static readonly waterSolubleVitamins: NutrientMetadata[] = [
    buildMetadata('thiamin'),
    buildMetadata('riboflavin'),
    buildMetadata('niacinMg'),
    buildMetadata('pantothenicAcid'),
    buildMetadata('folicAcid'),
    buildMetadata('vitaminC'),
    buildMetadata('vitaminB6'),
    buildMetadata('vitaminB12'),
  ];

  public static readonly minerals: NutrientMetadata[] = [
    buildMetadata('phosphorous'),
    buildMetadata('zinc'),
    buildMetadata('magnesium'),
    buildMetadata('copper'),
    buildMetadata('selenium'),
    buildMetadata('manganese'),
    buildMetadata('iodine'),
  ];

  public static readonly suffixMapping: {
    [key in keyof NutritionLine]: string;
  } = {
    amount: 'Amt',
    roundedAmount: 'NLEAAmt',
    referenceDailyIntakeAmount: 'RDIAmt',
    uom: 'UnitOfMeasure',
    label: 'Label',
    level: '',
    shouldReferenceDailyIntake: '',
  };

  public static readonly nutrientBlockSuffixMapping: {
    [key in keyof NutritionBlockLine]: string;
  } = {
    amount: 'Amt',
    uom: 'UnitOfMeasure',
    label: 'Label',
  };

  public static readonly sleSuffixMapping: {
    [key in keyof NutritionBlockLine]: string;
  } = {
    amount: '',
    uom: 'UnitOfMeasure',
    label: 'Label',
  };
}
