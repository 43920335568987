import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PicRecordV1 } from './models/pic-record-v1';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MygfsNutritionService {
  constructor(private httpClient: HttpClient) {}

  /**
   * @deprecated use getNutrition instead
   */
  getMygfsNutrition(materialNumber: string): Observable<PicRecordV1[]> {
    return this.httpClient.get<PicRecordV1[]>(
      `/nutritionService/getNutritionInfo?offeringGroupId=00001&languageTypeCode=en&offeringId=${materialNumber}`,
    );
  }
}
