import { formatDate as commonFormatDate } from '@angular/common';
import moment from 'moment';

const WEEKDAY_MONTH_DAY_MAY = 'EEEE, MMM dd';
const DAY_MONTH_DATE = 'EEEE, MMM. dd';
const DAY_MONTH_DATE_TIME = 'EEEE, MMM. dd, h:mma';
const DAY_MONTH_DATE_TIME_MAY = 'EEEE, MMM dd, h:mma';
const DAY_MONTH_DATE_YEAR = 'EEEE, MMM. dd, yyyy';
const DAY_MONTH_DATE_YEAR_MAY = 'EEEE, MMM dd, yyyy';

export interface NaooDateFormats {
  shortDate: string;
  mediumDate: string;
  fullDate: string;
  weekDay: string;
  exportDate: string;
  longDateAbbr: string;
  longDateAbbrMay: string;
  longDateNoDay: string;
  longDateNoDayAbbrMo: string;
  longDateTime: string;
  shortYearDate: string;
  shortYearDateTime: string;
  mediumTimeNoBreak: string;
  dayMonthDate: string;
  monthDay: string;
  time: string;
  time24: string;
  weekdayMonthDay: string;
  weekdayMonthDay_may: string;
  monthDayTime: string;
  weekdayMonthDayTime: string;
  shortWeekdayMonthDay: string;
  todayMonthDay: string;
  shortDayMonthDate: string;
  [key: string]: string;
}

export const dateFormats: { [locale: string]: NaooDateFormats } = {
  en_CA: {
    shortDate: 'MM/dd/yyyy',
    mediumDate: 'EE MM/dd/yyyy',
    fullDate: 'EEEE, MM/dd/yyyy',
    weekDay: 'EEEE',
    exportDate: 'MMddyyyy',
    longDateAbbr: DAY_MONTH_DATE_YEAR,
    longDateAbbrMay: DAY_MONTH_DATE_YEAR_MAY,
    longDateNoDay: 'MMMM d, yyyy',
    longDateNoDayAbbrMo: 'MMM d, yyyy',
    longDateTime: 'MMM dd, yyyy h:mm a',
    shortYearDate: 'MM/dd/yy',
    shortYearDateTime: 'MM/dd/yy h:mm a',
    mediumTimeNoBreak: `h:mm'&nbsp;'a`,
    fullDayShortDate: 'dddd, MM/DD/YYYY',
    dayMonthDate: DAY_MONTH_DATE,
    monthDay: 'MM/dd',
    time: 'h:mma',
    time24: 'H:mm',
    // add a period after 3 letter abbreviations except "May" is not an abbreviation so doesn't need a period.
    weekdayMonthDay: DAY_MONTH_DATE,
    weekdayMonthDay_may: WEEKDAY_MONTH_DAY_MAY,
    monthDayTime: 'MM/dd, h:mma',
    monthDayTime2: 'MM/DD, hh:mmA',
    shortWeekdayMonthDay: 'EEE, MMM d',
    todayMonthDay: "'today', MMM d",
    weekdayMonthDayTime: DAY_MONTH_DATE_TIME,
    weekdayMonthDayTime_may: DAY_MONTH_DATE_TIME_MAY,
    shortDayMonthDate: 'EE MMM dd',
  },
  en_US: {
    shortDate: 'MM/dd/yyyy',
    mediumDate: 'EE MM/dd/yyyy',
    fullDate: 'EEEE, MM/dd/yyyy',
    weekDay: 'EEEE',
    exportDate: 'MMddyyyy',
    longDateAbbr: DAY_MONTH_DATE_YEAR,
    longDateAbbrMay: DAY_MONTH_DATE_YEAR_MAY,
    longDateNoDay: 'MMMM d, yyyy',
    longDateNoDayAbbrMo: 'MMM d, yyyy',
    longDateTime: 'MMM dd, yyyy h:mm a',
    shortYearDate: 'MM/dd/yy',
    shortYearDateTime: 'MM/dd/yy h:mm a',
    mediumTimeNoBreak: `h:mm'&nbsp;'a`,
    fullDayShortDate: 'dddd, MM/DD/YYYY',
    dayMonthDate: DAY_MONTH_DATE,
    monthDay: 'MM/dd',
    time: 'h:mma',
    time24: 'H:mm',
    // add a period after 3 letter abbreviations except "May" is not an abbreviation so doesn't need a period.
    weekdayMonthDay: DAY_MONTH_DATE,
    weekdayMonthDay_may: WEEKDAY_MONTH_DAY_MAY,
    monthDayTime: 'MM/dd, h:mma',
    monthDayTime2: 'MM/DD, hh:mmA',
    shortWeekdayMonthDay: 'EEE, MMM d',
    todayMonthDay: "'today', MMM d",
    weekdayMonthDayTime: DAY_MONTH_DATE_TIME,
    weekdayMonthDayTime_may: DAY_MONTH_DATE_TIME_MAY,
    shortDayMonthDate: 'EE MMM dd',
  },
  fr_CA: {
    shortDate: 'dd/MM/yyyy',
    mediumDate: 'EE dd/MM/yyyy',
    fullDate: 'EEEE, dd/MM/yyyy',
    weekDay: 'EEEE',
    exportDate: 'ddMMyyyy',
    longDateAbbr: 'EEEE, dd MMM, yyyy',
    longDateAbbrMay: 'EEEE, dd MMM, yyyy',
    longDateNoDay: 'd MMMM yyyy',
    longDateNoDayAbbrMo: 'd MMM yyyy',
    longDateTime: 'dd MMM yyyy, H:mm',
    shortYearDate: 'dd/MM/yy',
    shortYearDateTime: 'dd/MM/yy H:mm',
    mediumTimeNoBreak: 'H:mm',
    fullDayShortDate: 'dddd, DD/MM/YYYY',
    dayMonthDate: 'EEEE, dd MMM',
    monthDay: 'dd/MM',
    time: 'H:mm',
    time24: 'H:mm',
    // add a period after 3 letter abbreviations except "May" is not an abbreviation so doesn't need a period.
    // except apparently french gets the period in the formatting
    weekdayMonthDay: 'EEEE, dd MMM',
    weekdayMonthDay_may: 'EEEE, dd MMM',
    monthDayTime: 'dd/MM, H:mm',
    monthDayTime2: 'DD/MM, hh:mmA',
    shortWeekdayMonthDay: 'EEE, d MMM',
    todayMonthDay: "'aujourd''hui', d MMM",
    weekdayMonthDayTime: 'EEEE, dd MMM, H:mm',
    weekdayMonthDayTime_may: 'EEEE, dd MMM, H:mm',
    shortDayMonthDate: 'EE dd MMM',
  },
};

export class SapDateTimeFormat {
  public static DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
  public static DATE_ONLY = 'YYYY-MM-DD';
}

export function parseDate(date?: string): Date | undefined {
  if (!date) {
    return undefined;
  }

  const localDate = new Date(date);
  return new Date(
    Date.UTC(
      localDate.getUTCFullYear(),
      localDate.getUTCMonth(),
      localDate.getUTCDate(),
    ),
  );
}

export function getCurrentUnixTime(): number {
  return Math.floor(new Date().getTime() / 1000);
}

export function formatDate(
  date?: Date | string,
  timezone: string = 'UTC',
): string | undefined {
  return date
    ? commonFormatDate(date, 'yyyy-MM-dd', 'en-US', timezone)
    : undefined;
}

export function formatDateWithoutTimeZone(
  date?: Date | string,
): string | undefined {
  return formatDate(date, '');
}

export function addDaysToDate(date: Date, days: number): Date {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function timeZoneCode(date: string, customerTimeZone: string): string {
  return moment.tz(date, customerTimeZone).zoneAbbr();
}
