import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { MygfsNutritionService } from '../../services/mygfs-nutrition/mygfs-nutrition.service';
import { Observable, of } from 'rxjs';
import { NutritionActions } from './nutrition.actions';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { selectAllNutritionRecords } from './nutrition.selectors';
import { NutritionRecordStatus } from './nutrition.state';
import { MygfsNutritionTransformerService } from '../mygfs-nutrition/mygfs-nutrition-transformer.service';
import { PicRecordV1 } from '../../services/mygfs-nutrition/models/pic-record-v1';
import { SharedActions } from '../shared/shared.actions';
import {
  selectCustomerBrand,
  selectIsFeatureEnabled,
} from '../session/session.selectors';
import { CustomerBrand } from '../../services/session/models/session-record';
import { NutritionTransformerService } from './nutrition-transformer.service';
import { NutritionService } from '../../services/nutrition/nutrition.service';

@Injectable()
export class NutritionEffects {
  private readonly newNutritionFeatureFlag = 'new-us-nutrition';

  constructor(
    private actions$: Actions,
    private store: Store,
    private myGfsNutritionService: MygfsNutritionService,
    private nutritionService: NutritionService,
    private myGfsNutritionTransformer: MygfsNutritionTransformerService,
    private nutritionTransformerService: NutritionTransformerService,
  ) {}

  loadNutrition$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(NutritionActions.loadNutrition),
      concatLatestFrom(() => [
        this.store.select(selectAllNutritionRecords),
        this.store.select(selectCustomerBrand),
      ]),
      mergeMap(([action, nutritionRecords, customerBrand]) => {
        const queuedMaterialNumbers = action.materialNumbers.filter(
          (materialNumber) =>
            NutritionRecordStatus.Queued ===
            nutritionRecords[materialNumber]?.status,
        );
        if (
          CustomerBrand.GFS_US !== customerBrand ||
          !queuedMaterialNumbers.length
        ) {
          return of(
            SharedActions.noOperation(
              'Nutrition for material numbers are not in queued status',
            ),
          );
        }
        const dispatchActions: Action[] = [];
        queuedMaterialNumbers.forEach((materialNumber) =>
          dispatchActions.push(NutritionActions.getNutrition(materialNumber)),
        );
        return dispatchActions;
      }),
    );
  });

  getNutrition$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(NutritionActions.getNutrition),
      concatLatestFrom(() => [
        this.store.select(selectAllNutritionRecords),
        this.store.select(selectIsFeatureEnabled(this.newNutritionFeatureFlag)),
      ]),
      mergeMap(([action, nutritionRecords, isNewNutritionFeatureEnabled]) => {
        const materialNumber = action.materialNumber;
        if (
          nutritionRecords[materialNumber].status !==
          NutritionRecordStatus.Requested
        ) {
          return of(
            SharedActions.noOperation(
              'Nutrition for material number not in requested status',
            ),
          );
        }

        if (isNewNutritionFeatureEnabled) {
          return this.nutritionService.getNutrition(materialNumber).pipe(
            map((record) =>
              NutritionActions.getNutritionSuccess(
                materialNumber,
                this.nutritionTransformerService.transformPicRecords([record]),
              ),
            ),
            catchError(() =>
              of(NutritionActions.getNutritionError(materialNumber)),
            ),
            takeUntil(
              this.actions$.pipe(ofType(NutritionActions.clearNutrition)),
            ),
          );
        }

        return this.myGfsNutritionService
          .getMygfsNutrition(materialNumber)
          .pipe(
            switchMap((records: PicRecordV1[]) =>
              of(
                NutritionActions.getNutritionSuccess(
                  materialNumber,
                  this.myGfsNutritionTransformer.transformPicRecords(records),
                ),
              ),
            ),
            catchError(() =>
              of(NutritionActions.getNutritionError(materialNumber)),
            ),
            takeUntil(
              this.actions$.pipe(ofType(NutritionActions.clearNutrition)),
            ),
          );
      }),
    );
  });
}
