import { createReducer, on } from '@ngrx/store';
import { SmartCartRecommendationsActions } from './smart-cart-recommendations.actions';
import {
  initialSmartCartRecommendationState,
  smartCartRecommendationAdapter,
  SmartCartRecommendationState,
} from './smart-cart-recommendations.state';

export const smartCartRecommendationsReducer = createReducer(
  initialSmartCartRecommendationState,
  on(
    SmartCartRecommendationsActions.getSmartCartRecommendationsSuccess,
    (state, action): SmartCartRecommendationState => ({
      ...state,
      smartCartRecommendation: smartCartRecommendationAdapter.setAll(
        action.smartCartRecommendations,
        state.smartCartRecommendation,
      ),
      hasLoaded: true,
      hasErrored: false,
    }),
  ),
);
