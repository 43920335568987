import { CurrencyPipe } from '@angular/common';
import { Localized } from '../models/localized';
import {
  Language,
  Locale,
} from '../../core/services/session/models/session-record';
import { getWeightUom } from '../../core/store/material-price/material-price.util';

export class LocalizedUtilities {
  private static readonly currencyPipes: Map<Locale, CurrencyPipe> = new Map([
    [Locale.en_CA, new CurrencyPipe(Locale.en_CA)],
    [Locale.fr_CA, new CurrencyPipe(Locale.fr_CA)],
  ]);

  public static getLocalizedValue<T>(
    localizedValues: Localized<T>,
    language: Language,
    shouldFallback: boolean = true,
  ): T | undefined {
    if (!localizedValues) {
      return undefined;
    }

    const englishValue = localizedValues.en;
    const frenchValue = localizedValues.fr;

    let value: T;
    if (Language.fr === language) {
      value = shouldFallback ? frenchValue || englishValue : frenchValue;
    } else {
      value = shouldFallback ? englishValue || frenchValue : englishValue;
    }

    return value || undefined;
  }

  public static getLocalizedStringValue(
    localizedString: Localized<string>,
    language: Language,
    shouldFallback: boolean = true,
  ): string {
    return (
      this.getLocalizedValue(localizedString, language, shouldFallback) ?? ''
    );
  }

  public static getLocalizedPrice(
    locale: Locale,
    value: string | number,
    weightUom?: string,
  ) {
    if (!value || value === '0') {
      return undefined;
    }

    const pipe =
      this.currencyPipes.get(locale) ?? this.currencyPipes.get(Locale.en_CA);
    let result = pipe.transform(value, 'CAD', 'symbol-narrow', '1.2-2');

    const weightUomDisplay = getWeightUom(weightUom);
    if (!!weightUomDisplay) {
      result += `/${weightUomDisplay}`;
    }

    return result;
  }

  public static getLocalizedBoolean(
    value: boolean,
    language: Language,
  ): string {
    return value ? (language === Language.fr ? 'O' : 'Y') : 'N';
  }
}
